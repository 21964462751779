@font-face {
  font-family: 'Montserrat-Regular';
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Montserrat-Italic';
  src: url("./fonts/Montserrat/Montserrat-Italic.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Montserrat/Montserrat-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url("./fonts/Montserrat/Montserrat-ExtraBold.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype"); }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("./fonts/Montserrat/Montserrat-Bold.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Montserrat-Black';
  src: url("./fonts/Montserrat/Montserrat-Black.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Montserrat/Montserrat-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'Lato-Black';
  src: url("./fonts/Lato/Lato-Black.ttf");
  /* IE9 Compat Modes */
  src: url("./fonts/Lato/Lato-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/Icomoon/icomoon.eot?hzbnj5");
  src: url("fonts/Icomoon/icomoon.eot?hzbnj5#iefix") format("embedded-opentype"), url("fonts/Icomoon/icomoon.woff2?hzbnj5") format("woff2"), url("fonts/Icomoon/icomoon.ttf?hzbnj5") format("truetype"), url("fonts/Icomoon/icomoon.woff?hzbnj5") format("woff"), url("fonts/Icomoon/icomoon.svg?hzbnj5#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-dl:before {
  content: "\e908";
  color: #fff; }

.icon-full-mail:before {
  content: "\e907";
  color: #fff; }

.icon-arrow-down-empty:before {
  content: "\e905";
  color: #fff; }

.icon-arrow-right-empty:before {
  content: "\e906";
  color: #fff; }

.icon-arrow-left-empty:before {
  content: "\e906";
  color: #fff;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.icon-mail:before {
  content: "\e900";
  color: #fff; }

.icon-cube-fb:before {
  content: "\e901";
  color: #fff; }

.icon-arrow-down:before {
  content: "\e902";
  color: #fff; }

.icon-circle-fb:before {
  content: "\e903";
  color: #fff; }

.icon-circle-twitter:before {
  content: "\e904";
  color: #fff; }

.navbar-nav > li {
  min-width: 130px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .navbar-nav > li > a {
    text-transform: uppercase;
    font-family: 'Montserrat-SemiBold';
    font-size: 0.85em;
    position: relative;
    color: #333333;
    padding: 10px 15px;
    width: 100%;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  .navbar-nav > li.current-menu-item, .navbar-nav > li:hover {
    background-color: #F09403;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .navbar-nav > li.current-menu-item > a, .navbar-nav > li:hover > a {
      color: #ffffff;
      text-decoration: none; }

.header-home,
.header-content {
  position: relative; }
  .header-home .wrap-header,
  .header-content .wrap-header {
    background-size: contain;
    background-position: center;
    width: 100%;
    height: auto;
    padding: 15px 0; }
    .header-home .wrap-header > .container,
    .header-content .wrap-header > .container {
      z-index: 1001; }
    .header-home .wrap-header h1,
    .header-content .wrap-header h1 {
      font-family: 'Montserrat-regular'; }
      .header-home .wrap-header h1 strong,
      .header-content .wrap-header h1 strong {
        font-family: "Montserrat-bold" !important; }
    .header-home .wrap-header .navbar .space-between,
    .header-content .wrap-header .navbar .space-between {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .header-home .wrap-header .navbar .space-between .logo,
      .header-content .wrap-header .navbar .space-between .logo {
        padding: 0; }
      .header-home .wrap-header .navbar .space-between .nav-right,
      .header-content .wrap-header .navbar .space-between .nav-right {
        padding: 0; }
        .header-home .wrap-header .navbar .space-between .nav-right .link-nav,
        .header-content .wrap-header .navbar .space-between .nav-right .link-nav {
          display: inline-flex;
          float: right; }
      .header-home .wrap-header .navbar .space-between .navbar-brand img,
      .header-content .wrap-header .navbar .space-between .navbar-brand img {
        width: 250px;
        height: auto; }
      .header-home .wrap-header .navbar .space-between .link-nav,
      .header-content .wrap-header .navbar .space-between .link-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center; }
        .header-home .wrap-header .navbar .space-between .link-nav .icon,
        .header-content .wrap-header .navbar .space-between .link-nav .icon {
          display: flex;
          flex-direction: row;
          align-items: center; }
          .header-home .wrap-header .navbar .space-between .link-nav .icon::before,
          .header-content .wrap-header .navbar .space-between .link-nav .icon::before {
            font-size: 1.2em; }
        .header-home .wrap-header .navbar .space-between .link-nav .language,
        .header-content .wrap-header .navbar .space-between .link-nav .language {
          position: relative;
          cursor: pointer; }
          .header-home .wrap-header .navbar .space-between .link-nav .language:after,
          .header-content .wrap-header .navbar .space-between .link-nav .language:after {
            content: '';
            position: absolute;
            top: 9px;
            right: 0px;
            border-top: 8px solid #ffffff;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            z-index: 1; }
          .header-home .wrap-header .navbar .space-between .link-nav .language select,
          .header-content .wrap-header .navbar .space-between .link-nav .language select {
            background: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            border: 0;
            padding-right: 30px;
            font-family: 'Lato-Black', sans-serif;
            color: #ffffff;
            z-index: 2;
            position: relative;
            text-transform: uppercase; }
            .header-home .wrap-header .navbar .space-between .link-nav .language select option,
            .header-content .wrap-header .navbar .space-between .link-nav .language select option {
              color: #333333; }
    .header-home .wrap-header .navbar .navbar-collapse,
    .header-content .wrap-header .navbar .navbar-collapse {
      padding-top: 30px; }
      .header-home .wrap-header .navbar .navbar-collapse .navbar-nav,
      .header-content .wrap-header .navbar .navbar-collapse .navbar-nav {
        background-color: #ffffff; }
        .header-home .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item,
        .header-content .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item {
          min-width: 130px;
          text-align: center;
          display: flex;
          align-items: center; }
          .header-home .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item.active, .header-home .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item:hover,
          .header-content .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item.active,
          .header-content .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item:hover {
            background-color: #F09403;
            transition: all 0.2s; }
            .header-home .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item.active .nav-link, .header-home .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item:hover .nav-link,
            .header-content .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item.active .nav-link,
            .header-content .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item:hover .nav-link {
              color: #ffffff; }
          .header-home .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link,
          .header-content .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
            text-transform: uppercase;
            font-family: 'Montserrat-SemiBold';
            font-size: 0.85em;
            position: relative;
            color: #333333;
            padding: 10px 15px;
            width: 100%;
            text-align: center;
            transition: all 0.2s; }
    .header-home .wrap-header h1,
    .header-content .wrap-header h1 {
      padding: 120px 15px;
      font-family: 'Montserrat-Bold', sans-serif;
      font-size: 2.6em;
      color: #ffffff;
      text-align: center; }
      .header-home .wrap-header h1 span,
      .header-content .wrap-header h1 span {
        font-family: 'Montserrat-Regular', sans-serif; }

.header-content .wrap-header {
  padding: 0;
  position: relative;
  height: 100%; }
  .header-content .wrap-header .navbar {
    bottom: 0;
    padding: 30px 0px;
    background-color: rgba(4, 100, 141, 0.5);
    width: 100%; }
    .header-content .wrap-header .navbar .background-nav {
      display: block;
      margin: auto; }
      .header-content .wrap-header .navbar .background-nav .space-between {
        padding: 0 15px; }

.wrap-header.page-interieur:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(4, 100, 141, 0.5); }

.wrap-header > .container {
  position: relative;
  z-index: 1; }

@media (max-width: 1199.99px) {
  .header-home .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item,
  .header-content .wrap-header .navbar .navbar-collapse .navbar-nav .nav-item {
    min-width: initial; } }

@media (max-width: 991.99px) {
  .header-home.header-content,
  .header-content.header-content {
    height: initial; }
    .header-home.header-content .navbar,
    .header-content.header-content .navbar {
      width: 100%;
      z-index: 15;
      position: relative; }
  .header-home .wrap-header h1,
  .header-content .wrap-header h1 {
    padding: 40px 15px 10px 15px;
    font-size: 1.8em; }
  .header-home .wrap-header .navbar .background-nav,
  .header-content .wrap-header .navbar .background-nav {
    width: 100%; }
  .header-home .wrap-header .navbar .space-between .navbar-brand img,
  .header-content .wrap-header .navbar .space-between .navbar-brand img {
    width: 200px; }
  .header-home .wrap-header .navbar .nav-right .link-nav,
  .header-content .wrap-header .navbar .nav-right .link-nav {
    display: none; }
  .header-home .wrap-header .navbar .navbar-collapse,
  .header-content .wrap-header .navbar .navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    height: auto !important; }
    .header-home .wrap-header .navbar .navbar-collapse .navbar-nav,
    .header-content .wrap-header .navbar .navbar-collapse .navbar-nav {
      height: 100%;
      justify-content: center;
      align-items: center; }
    .header-home .wrap-header .navbar .navbar-collapse .nav-link,
    .header-content .wrap-header .navbar .navbar-collapse .nav-link {
      text-align: center; }
      .header-home .wrap-header .navbar .navbar-collapse .nav-link .nav-item,
      .header-content .wrap-header .navbar .navbar-collapse .nav-link .nav-item {
        min-width: initial; }
  .header-home .wrap-header .navbar-toggler,
  .header-content .wrap-header .navbar-toggler {
    position: fixed;
    width: 40px;
    height: 40px;
    padding: 10px;
    z-index: 1002;
    bottom: 0px;
    right: 0px;
    border: none;
    border-radius: 0px;
    background: #ffffff; }
    .header-home .wrap-header .navbar-toggler .icon-bar,
    .header-content .wrap-header .navbar-toggler .icon-bar {
      width: 20px;
      height: 2px;
      background-color: #B6B6B6;
      display: block;
      transition: all 0.2s;
      margin-top: 4px; }
      .header-home .wrap-header .navbar-toggler .icon-bar.top-bar,
      .header-content .wrap-header .navbar-toggler .icon-bar.top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%; }
      .header-home .wrap-header .navbar-toggler .icon-bar.middle-bar,
      .header-content .wrap-header .navbar-toggler .icon-bar.middle-bar {
        opacity: 0; }
      .header-home .wrap-header .navbar-toggler .icon-bar.bottom-bar,
      .header-content .wrap-header .navbar-toggler .icon-bar.bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%; }
    .header-home .wrap-header .navbar-toggler.collapsed .top-bar,
    .header-content .wrap-header .navbar-toggler.collapsed .top-bar {
      transform: rotate(0); }
    .header-home .wrap-header .navbar-toggler.collapsed .middle-bar,
    .header-content .wrap-header .navbar-toggler.collapsed .middle-bar {
      opacity: 1; }
    .header-home .wrap-header .navbar-toggler.collapsed .bottom-bar,
    .header-content .wrap-header .navbar-toggler.collapsed .bottom-bar {
      transform: rotate(0); } }

.action-button {
  background-color: #F09403;
  color: #ffffff;
  padding: 10px 40px;
  border-radius: 20px;
  transition: all 0.2s;
  text-decoration: none;
  margin: 15px 0;
  text-transform: uppercase;
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 0.8em; }
  .action-button:hover {
    background-color: #333333;
    color: #ffffff;
    text-decoration: none;
    transition: all 0.2s; }
  .action-button.carre {
    border-radius: 0px;
    font-size: 1em;
    padding: 15px 20px; }
    .action-button.carre:before {
      content: "\e907";
      color: #ffffff;
      font-family: 'Icomoon';
      margin-right: 15px;
      font-size: 1em;
      padding: 0; }

main {
  background-color: #f3f3f3; }
  main p {
    color: #333333;
    font-size: 0.95em; }

img.aligncenter {
  display: block;
  margin: 0 auto; }

footer {
  background-color: #043447;
  padding: 50px 0; }
  footer .top-footer {
    display: flex;
    flex-direction: row; }
    footer .top-footer img {
      width: 100%;
      height: auto;
      min-width: 150px; }
    footer .top-footer .social-media {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      height: 100%; }
      footer .top-footer .social-media a {
        text-decoration: none !important; }
      footer .top-footer .social-media .icon-circle-fb {
        margin-right: 5px; }
      footer .top-footer .social-media .icon:before {
        font-size: 3em; }
  footer .bottom-footer {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    footer .bottom-footer .adress {
      text-align: center;
      min-width: 200px;
      flex-basis: 33%;
      margin-bottom: 2rem; }
      footer .bottom-footer .adress h5 {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1em;
        color: #F09403; }
      footer .bottom-footer .adress p,
      footer .bottom-footer .adress a {
        font-family: 'Montserrat-Regular', sans-serif;
        font-size: 0.8em;
        color: #849FAF;
        margin-bottom: 0;
        text-decoration: none; }
        footer .bottom-footer .adress p:hover,
        footer .bottom-footer .adress a:hover {
          text-decoration: none; }
        footer .bottom-footer .adress p.adress-txt,
        footer .bottom-footer .adress a.adress-txt {
          margin-bottom: 15px; }
    footer .bottom-footer .link-bottom {
      flex-basis: 100%;
      text-align: right;
      margin: 30px 0 0;
      font-family: 'Montserrat-Regular', sans-serif;
      font-size: 0.8em;
      color: #849FAF;
      width: 100%; }
      footer .bottom-footer .link-bottom a,
      footer .bottom-footer .link-bottom span {
        font-size: .875rem;
        color: #ffffff; }

@media (max-width: 575.99px) {
  footer {
    padding: 50px 0; }
    footer .top-footer {
      display: flex;
      flex-direction: row;
      justify-content: center; }
      footer .top-footer img {
        width: 100%;
        height: auto;
        min-width: 100px; }
      footer .top-footer .social-media {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        height: 100%; }
        footer .top-footer .social-media .icon:before {
          font-size: 3em; }
    footer .bottom-footer {
      padding-top: 50px; }
      footer .bottom-footer .adress {
        text-align: center;
        min-width: 200px;
        margin-top: 30px; }
        footer .bottom-footer .adress h5 {
          font-family: 'Montserrat-Bold', sans-serif;
          font-size: 1em;
          color: #F09403; }
        footer .bottom-footer .adress p,
        footer .bottom-footer .adress a {
          font-family: 'Montserrat-Regular', sans-serif;
          font-size: 0.8em;
          color: #849FAF;
          margin-bottom: 0;
          text-decoration: none; }
          footer .bottom-footer .adress p:hover,
          footer .bottom-footer .adress a:hover {
            text-decoration: none; }
          footer .bottom-footer .adress p.adress-txt,
          footer .bottom-footer .adress a.adress-txt {
            margin-bottom: 15px; } }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* DESKTOP : 1199px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLETP : 990px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLET : 768px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLE : 640px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MOBILEP : 480px px */
/* ----------------------------------------------------- */
@media (max-width: 480px) {
  footer .bottom-footer .adress {
    flex-basis: 100%;
    min-width: 0px; } }

.wrap-3-pictures {
  padding: 30px 15px;
  background-color: #043447; }
  .wrap-3-pictures .image {
    position: relative;
    height: 235px; }
    .wrap-3-pictures .image .linear-gradient {
      position: relative;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#07648d', endColorstr='#007db9e8', GradientType=1);
      /* IE6-9 */ }
  .wrap-3-pictures .content-txt {
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px 30px;
    text-align: center; }
    .wrap-3-pictures .content-txt h2 {
      color: white;
      font-family: 'Montserrat-ExtraBold', sans-serif;
      text-transform: uppercase;
      font-size: 1.4em; }
    .wrap-3-pictures .content-txt p {
      font-family: 'Montserrat-Bold', sans-serif;
      font-size: 1em;
      color: orange;
      text-transform: uppercase; }

.wrap-edito .no-padding-left {
  padding-left: 50px; }

.wrap-edito .flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px; }
  .wrap-edito .flex-center h3 {
    color: #043447;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.75em;
    text-transform: uppercase;
    display: inline; }
  .wrap-edito .flex-center .separator {
    content: "";
    background-color: #043447;
    width: 80%;
    height: 1px; }

.wrap-edito p {
  text-align: justify;
  color: #333333;
  font-size: 1em;
  font-family: 'Montserrat-Regular', sans-serif;
  margin: 35px 0 50px 0; }

.wrap-edito .orange {
  color: orange;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: 900; }
  .wrap-edito .orange:hover {
    color: #333333;
    transition: all 0.2s; }

.wrap-edito .images-col-6 {
  padding-right: 0; }
  .wrap-edito .images-col-6 img {
    width: 100%;
    object-fit: cover;
    height: 100%; }

.wrap-actus {
  padding: 50px 0; }
  .wrap-actus .flex {
    display: flex;
    align-items: center; }
    .wrap-actus .flex h3 {
      font-family: 'Montserrat-Bold', sans-serif;
      font-size: 1.75em;
      text-transform: uppercase;
      text-align: center; }
    .wrap-actus .flex .separator {
      content: "";
      background-color: #043447;
      width: 100%;
      height: 1px; }
  .wrap-actus .item {
    margin-top: 50px; }
    .wrap-actus .item img {
      width: 100%;
      height: 247px;
      object-fit: cover;
      padding-bottom: 20px; }
    .wrap-actus .item .date {
      color: orange;
      font-family: 'Montserrat-Black', sans-serif;
      font-size: 1.4em;
      margin: 0;
      padding: 0;
      line-height: 1; }
    .wrap-actus .item .title {
      display: flex;
      flex-direction: column;
      padding-left: 20px; }
      .wrap-actus .item .title h4 {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1.25em;
        text-transform: uppercase;
        color: #043447;
        margin: 0; }
    .wrap-actus .item p {
      color: #333333;
      font-size: 1em;
      font-family: 'Montserrat-Regular', sans-serif;
      padding: 20px 0; }

.wrap-job-dl-container {
  width: 100%; }

.wrap-job-dl-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch; }

.wrap-job-dl .left,
.wrap-job-dl .right {
  padding: 70px 50px;
  color: #ffffff; }

.wrap-job-dl h4 {
  font-family: 'Monserrat-Bold', sans-serif;
  font-size: 1.75em;
  text-transform: uppercase; }

.wrap-job-dl p {
  padding: 25px 0; }

.wrap-job-dl .separator {
  content: "";
  background-color: #ffffff;
  width: 70%;
  height: 1px; }

.wrap-job-dl .left {
  text-align: right; }
  .wrap-job-dl .left h4 {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .wrap-job-dl .left h4:before {
      content: "";
      background-color: #ffffff;
      width: 35%;
      height: 1px;
      display: block;
      margin-right: 30px; }

.wrap-job-dl .right {
  text-align: left; }
  .wrap-job-dl .right h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .wrap-job-dl .right h4:after {
      content: "";
      background-color: #ffffff;
      width: 35%;
      height: 1px;
      display: block;
      margin-left: 30px; }

.wrap-3-pictures {
  padding: 30px 15px;
  background-color: #043447; }
  .wrap-3-pictures .image {
    position: relative;
    height: 235px; }
    .wrap-3-pictures .image .linear-gradient {
      position: relative;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#07648d', endColorstr='#007db9e8', GradientType=1);
      /* IE6-9 */ }
  .wrap-3-pictures .content-txt {
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px 30px;
    text-align: center; }
    .wrap-3-pictures .content-txt h2 {
      color: white;
      font-family: 'Montserrat-ExtraBold', sans-serif;
      text-transform: uppercase;
      font-size: 1.4em; }
    .wrap-3-pictures .content-txt p {
      font-family: 'Montserrat-Bold', sans-serif;
      font-size: 1em;
      color: orange;
      text-transform: uppercase; }

.wrap-edito .no-padding-left {
  padding-left: 50px; }

.wrap-edito .flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px; }
  .wrap-edito .flex-center h3 {
    color: #043447;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.75em;
    text-transform: uppercase;
    display: inline; }
  .wrap-edito .flex-center .separator {
    content: "";
    background-color: #043447;
    width: 80%;
    height: 1px; }

.wrap-edito p {
  text-align: justify;
  color: #333333;
  font-size: 1em;
  font-family: 'Montserrat-Regular', sans-serif;
  margin: 35px 0 50px 0; }

.wrap-edito .orange {
  color: orange;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;
  font-weight: 900; }
  .wrap-edito .orange:hover {
    color: #333333;
    transition: all 0.2s; }

.wrap-edito .images-col-6 {
  padding-right: 0; }
  .wrap-edito .images-col-6 img {
    width: 100%;
    object-fit: cover;
    height: 100%; }

.wrap-actus {
  padding: 50px 0; }
  .wrap-actus .flex {
    display: flex;
    align-items: center; }
    .wrap-actus .flex h3 {
      font-family: 'Montserrat-Bold', sans-serif;
      font-size: 1.75em;
      text-transform: uppercase;
      text-align: center; }
    .wrap-actus .flex .separator {
      content: "";
      background-color: #043447;
      width: 100%;
      height: 1px; }
  .wrap-actus .item {
    margin-top: 50px; }
    .wrap-actus .item img {
      width: 100%;
      height: 247px;
      object-fit: cover;
      padding-bottom: 20px; }
    .wrap-actus .item .date {
      color: orange;
      font-family: 'Montserrat-Black', sans-serif;
      font-size: 1.4em;
      margin: 0;
      padding: 0;
      line-height: 1; }
    .wrap-actus .item .title {
      display: flex;
      flex-direction: column;
      padding-left: 20px; }
      .wrap-actus .item .title h4 {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1.25em;
        text-transform: uppercase;
        color: #043447;
        margin: 0; }
    .wrap-actus .item p {
      color: #333333;
      font-size: 1em;
      font-family: 'Montserrat-Regular', sans-serif;
      padding: 20px 0; }

.wrap-job-dl .left,
.wrap-job-dl .right {
  padding: 70px 50px;
  color: #ffffff; }

.wrap-job-dl h4 {
  font-family: 'Monserrat-Bold', sans-serif;
  font-size: 1.75em;
  text-transform: uppercase; }

.wrap-job-dl p {
  padding: 25px 0; }

.wrap-job-dl .separator {
  content: "";
  background-color: #ffffff;
  width: 70%;
  height: 1px; }

.wrap-job-dl .left {
  text-align: right; }
  .wrap-job-dl .left h4 {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .wrap-job-dl .left h4:before {
      content: "";
      background-color: #ffffff;
      width: 35%;
      height: 1px;
      display: block;
      margin-right: 30px; }

.wrap-job-dl .right {
  text-align: left; }
  .wrap-job-dl .right h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .wrap-job-dl .right h4:after {
      content: "";
      background-color: #ffffff;
      width: 35%;
      height: 1px;
      display: block;
      margin-left: 30px; }

@media (max-width: 1199.99px) {
  .wrap-3-pictures .image {
    height: 192px; }
  .wrap-edito .no-padding-left {
    padding-left: 15px; }
  .wrap-edito .flex-center .separator {
    width: 60%; }
  .wrap-edito .images-col-6 {
    padding-right: 0; }
  .wrap-job-dl .left,
  .wrap-job-dl .right {
    padding: 60px 50px; } }

@media (max-width: 991.99px) {
  .wrap-3-pictures {
    padding: 30px 15px; }
    .wrap-3-pictures .image {
      height: 175px;
      margin-bottom: 15px; }
    .wrap-3-pictures .content-txt {
      padding: 40px 10px; }
      .wrap-3-pictures .content-txt h2 {
        font-size: 1.2em; } }

@media (max-width: 767.99px) {
  .wrap-3-pictures {
    padding: 30px 15px; }
    .wrap-3-pictures .image {
      height: 175px;
      margin-bottom: 15px; }
    .wrap-3-pictures .content-txt {
      padding: 50px 15px;
      width: 100%; }
      .wrap-3-pictures .content-txt h2 {
        font-size: 1.2em; }
  .wrap-edito .no-padding-left {
    padding-left: 15px; }
  .wrap-edito .flex-center {
    padding-top: 50px; }
    .wrap-edito .flex-center h3 {
      font-size: 1.5em; }
    .wrap-edito .flex-center .separator {
      width: 60%; }
  .wrap-edito .images-col-6 {
    margin-top: 30px; }
  .wrap-actus .flex h3 {
    font-size: 1.5em; }
  .wrap-job-dl .wrap-job-dl-row {
    flex-wrap: wrap; }
  .wrap-job-dl .left,
  .wrap-job-dl .right {
    padding: 60px 50px; }
  .wrap-job-dl h4 {
    font-size: 1.5em; } }

@media screen and (max-width: 480px) {
  .wrap-job-dl-row {
    flex-wrap: wrap; } }

body {
  overflow-x: hidden; }

.clear {
  clear: both; }

a {
  color: #04648D;
  transition: all .4s ease-out; }
  a:hover {
    color: #F09403; }

body.single .single-content {
  padding: 4vh 0; }

img.separate {
  width: auto;
  max-width: 48%;
  margin-right: 1%; }
  img.separate:last-child {
    margin-right: 0; }

.grecaptcha-badge {
  visibility: hidden; }

/* ----------------------------------------------------- */
/* ANIMATIONS */
/* ----------------------------------------------------- */
@media (min-width: 768px) {
  .fadein {
    opacity: 0;
    position: relative;
    transition: all 300ms; }
    .fadein.fade-right {
      left: 30px;
      right: -30px; }
    .fadein.fade-left {
      right: 30px;
      left: -30px; }
    .fadein.fade-top {
      top: 15px; }
    .fadein.delayed {
      transition-delay: 200ms; }
    .fadein.delayed-1 {
      transition-delay: calc(1 * 100ms); }
    .fadein.delayed-2 {
      transition-delay: calc(2 * 100ms); }
    .fadein.delayed-3 {
      transition-delay: calc(3 * 100ms); }
    .fadein.delayed-4 {
      transition-delay: calc(4 * 100ms); }
    .fadein.delayed-5 {
      transition-delay: calc(5 * 100ms); }
    .fadein.delayed-6 {
      transition-delay: calc(6 * 100ms); }
    .fadein.delayed-7 {
      transition-delay: calc(7 * 100ms); }
    .fadein.delayed-8 {
      transition-delay: calc(8 * 100ms); }
    .fadein.delayed-9 {
      transition-delay: calc(9 * 100ms); }
    .fadein.delayed-10 {
      transition-delay: calc(10 * 100ms); } }

/* ----------------------------------------------------- */
/* WRAP EDITO */
/* ----------------------------------------------------- */
.wrap-edito p {
  margin-top: 20px !important;
  margin-bottom: 15px !important; }
  .wrap-edito p:last-of-type {
    margin-bottom: 30px !important; }

/* ----------------------------------------------------- */
/* WRAP CONTENT */
/* ----------------------------------------------------- */
.wrap-content {
  padding: 0; }
  .wrap-content h1 {
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 2.62em;
    color: #043447;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    padding-top: 50px; }
    .wrap-content h1:before {
      content: "";
      width: 30px;
      height: 30px;
      position: relative;
      display: block;
      background-color: #F09403;
      margin-right: 15px; }
  .wrap-content h2 {
    color: #043447;
    font-family: "Montserrat-Bold", sans-serif;
    text-transform: uppercase;
    font-size: 1.4em; }
  .wrap-content h3 {
    color: #043447;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.2em;
    text-transform: uppercase;
    display: inline; }
  .wrap-content img {
    max-width: 100%;
    height: auto; }
  .wrap-content .part-left,
  .wrap-content .part-right {
    margin: 50px 0; }
    .wrap-content .part-left img,
    .wrap-content .part-right img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .wrap-content .img-full img {
    width: 100%;
    height: auto;
    max-height: 260px;
    display: block;
    margin: auto;
    object-fit: cover; }
  .wrap-content .bold-orange {
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.5em;
    color: #F09403; }
  .wrap-content .italic-orange {
    font-family: 'Montserrat-Italic', sans-serif;
    font-size: 1.12em;
    color: #F09403;
    text-align: center;
    margin-bottom: 0; }
    .wrap-content .italic-orange:after {
      content: "";
      background-color: #D0D6D6;
      width: 50px;
      height: 1px;
      position: relative;
      display: block;
      margin: 50px auto 10px auto; }
  .wrap-content .card {
    border: none;
    margin-bottom: 30px;
    background-color: #F2F2F2; }
    .wrap-content .card .card-header {
      border: none;
      padding-bottom: 0; }
      .wrap-content .card .card-header .btn-link {
        color: #F09403;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left; }
        .wrap-content .card .card-header .btn-link:hover {
          text-decoration: none;
          color: #04648D; }
        .wrap-content .card .card-header .btn-link:before {
          padding-top: 1px;
          padding-left: 2px;
          text-align: center;
          content: "\e906";
          font-size: 1em;
          min-width: 47px;
          min-height: 47px;
          color: #ffffff;
          display: block;
          font-family: 'Icomoon';
          background-color: #F09403;
          border: 5px solid #F2F4F4;
          border-radius: 100%;
          -webkit-transform: rotateX(180deg);
          transform: rotate(90deg);
          transition: all 0.3s;
          margin-right: 15px; }
        .wrap-content .card .card-header .btn-link .adress {
          font-family: 'Montserrat-Regular', sans-serif;
          font-size: 0.7em;
          color: #043447;
          text-transform: initial; }
        .wrap-content .card .card-header .btn-link .post {
          line-height: 1;
          margin-top: 20px; }
        .wrap-content .card .card-header .btn-link.collapsed:before {
          text-align: center;
          content: "\e906";
          font-size: 1em;
          min-width: 47px;
          min-height: 47px;
          color: #ffffff;
          display: block;
          font-family: 'Icomoon';
          background-color: #043447;
          border: 5px solid #F2F4F4;
          border-radius: 100%;
          transform: none;
          transition: all 0.3s;
          margin-right: 15px; }
    .wrap-content .card .card-body {
      padding: 0px 96px 60px 96px;
      background-color: rgba(0, 0, 0, 0.03); }
      .wrap-content .card .card-body:before {
        content: "";
        width: 150px;
        height: 1px;
        background-color: #849FAF;
        position: relative;
        display: block;
        margin-bottom: 20px; }
      .wrap-content .card .card-body p {
        font-family: 'Montserrat-Regular', sans-serif;
        font-size: 0.8em;
        color: #043447;
        margin-bottom: 50px; }
  .wrap-content .link-dl {
    margin-bottom: 30px; }
    .wrap-content .link-dl a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 30px 15px;
      background-color: #F2F4F4;
      min-height: 150px;
      transition: all 0.3s; }
      .wrap-content .link-dl a:after {
        font-family: 'Icomoon';
        content: "\e908";
        color: #D0D6D6;
        font-size: 1.8em;
        margin-left: 5px; }
      .wrap-content .link-dl a .content .name-dl {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1em;
        color: #F09403;
        text-transform: uppercase;
        margin-bottom: 5px; }
      .wrap-content .link-dl a .content .files-dl {
        color: #043447;
        font-family: 'Montserrat-Regular', sans-serif;
        font-size: 0.8em;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px; }
        .wrap-content .link-dl a .content .files-dl:before {
          background-image: url("../images/pdf-logo.png");
          background-repeat: no-repeat;
          width: 20px;
          height: 30px;
          display: block;
          content: "";
          margin-right: 7px; }
      .wrap-content .link-dl a:hover {
        background-color: #F09403;
        transition: all 0.3s; }
        .wrap-content .link-dl a:hover:after {
          font-family: 'Icomoon';
          content: "\e908";
          color: #ffffff;
          font-size: 1.8em; }
        .wrap-content .link-dl a:hover .content .name-dl {
          font-family: 'Montserrat-Bold', sans-serif;
          font-size: 1em;
          color: #ffffff;
          text-transform: uppercase;
          margin-bottom: 5px; }
        .wrap-content .link-dl a:hover .content .files-dl {
          color: #ffffff;
          font-family: 'Montserrat-Regular', sans-serif;
          font-size: 0.8em;
          display: flex;
          flex-direction: row;
          margin-bottom: 5px; }
          .wrap-content .link-dl a:hover .content .files-dl:before {
            background-image: url("../images/pdf-logo.png");
            background-repeat: no-repeat;
            width: 20px;
            height: 30px;
            display: block;
            content: "";
            margin-right: 7px; }
  .wrap-content .slick-prev:before,
  .wrap-content .slick-next:before {
    color: #043447; }
  .wrap-content table {
    background-color: #DCE6F2;
    width: 100%;
    border: 1px solid #ffffff;
    border-collapse: collapse;
    color: #2A7DBF; }
    .wrap-content table th {
      background-color: #B9CDE5;
      text-align: center;
      font-weight: bold;
      padding: 1rem;
      border: 1px solid #ffffff; }
    .wrap-content table tr:nth-child(odd) {
      background-color: #DCE6F2; }
    .wrap-content table tr:nth-child(even) {
      background-color: #F2F2F2; }
    .wrap-content table td {
      padding: 1rem;
      border: 1px solid #ffffff; }
      .wrap-content table td img {
        margin: 0 auto !important; }

/* ----------------------------------------------------- */
/* CONTENT STD */
/* ----------------------------------------------------- */
.content-std h2 {
  color: #F09403;
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 1.5em;
  line-height: 1.5;
  margin-bottom: 1rem; }

.content-std h3 {
  font-family: 'Montserrat-Bold', sans-serif;
  font-size: 1.125em;
  line-height: 1.5;
  margin-bottom: 1rem; }

.content-std blockquote,
.content-std blockquote p {
  clear: both;
  font-family: 'Montserrat-Italic', sans-serif;
  font-size: 1.125rem;
  color: #F09403;
  text-align: center;
  margin-bottom: 0; }

.content-std hr {
  width: 50px;
  margin: 15px auto; }

.content-std img {
  max-width: 100%; }

.content-std img.alignleft {
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
  max-width: 25%;
  height: auto; }

.content-std img.alignright {
  float: right;
  margin-left: 30px;
  margin-bottom: 30px;
  max-width: 25%;
  height: auto; }

.content-std img.aligncenter {
  display: block;
  margin: 30px auto;
  height: auto; }

/* ----------------------------------------------------- */
/* PAGINATION */
/* ----------------------------------------------------- */
.pagination {
  display: block;
  position: relative;
  text-align: center;
  margin: 50px 0 10px; }
  .pagination a {
    display: inline-block;
    color: #F09403;
    font-size: 1.25em;
    margin: 0 20px; }
    .pagination a.prev {
      position: absolute;
      left: 0; }
    .pagination a.next {
      position: absolute;
      right: 0; }
  .pagination span {
    font-size: 1.25em; }

.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch; }
  .columns .column {
    flex-basis: 50%; }
    .columns .column li {
      padding-bottom: 1em; }

/* ----------------------------------------------------- */
/* WRAP JOB DL */
/* ----------------------------------------------------- */
.wrap-job-dl p {
  color: #ffffff; }

/* ----------------------------------------------------- */
/* COOKIE DISCLAIMER */
/* ----------------------------------------------------- */
#cookie_disclaimer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #F2F2F2;
  padding: 20px;
  color: #333;
  z-index: 1000; }
  #cookie_disclaimer .title {
    font-weight: 700;
    font-size: 1.225em;
    padding-right: 100px; }
  #cookie_disclaimer p {
    padding-right: 100px; }
  #cookie_disclaimer #cookie_stop {
    float: right;
    background: #043447;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-weight: 900;
    height: 100%;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: top;
    width: 70px;
    transition: all 500ms; }
    #cookie_disclaimer #cookie_stop:hover {
      background: black; }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* DESKTOP : 1199 px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLETP : 990px px */
/* ----------------------------------------------------- */
@media (max-width: 990px) {
  section.txt_img .img img {
    height: 100%;
    object-position: center;
    object-fit: cover; }
  .wrap-content table th,
  .wrap-content table td {
    padding: .2rem; } }

/* ----------------------------------------------------- */
/* TABLET : 768px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLE : 640px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* 600px */
/* ----------------------------------------------------- */
@media screen and (max-width: 600px) {
  .columns {
    flex-wrap: wrap; }
  .column {
    flex-basis: 100%; } }

/* ----------------------------------------------------- */
/* 550px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MOBILEP : 480px px */
/* ----------------------------------------------------- */
@media (max-width: 640px) {
  .wrap-content .card .card-header {
    padding: 0; }
    .wrap-content .card .card-header .btn-link {
      white-space: unset; } }

@media screen and (max-width: 1199px) {
  .form-std, .page-template-template-contact form {
    padding: 30px; } }

@media (max-width: 767.99px) {
  .wrap-content h1 {
    font-size: 1.5em;
    margin-bottom: 30px; }
  .wrap-content .part-left,
  .wrap-content .part-right {
    margin: 15px 0; }
  .wrap-content .card .card-header .btn-link {
    font-size: .9em; }
    .wrap-content .card .card-header .btn-link:before {
      padding-top: 5px; }
    .wrap-content .card .card-header .btn-link .post {
      white-space: initial; }
  .wrap-content .card .card-body {
    padding: 0px 15px 60px 15px; }
  .wrap-job-dl h4 {
    font-size: 1.1em; } }

#map {
  width: 100%;
  height: 730px; }

.infowindow_map {
  width: 230px; }
  .infowindow_map img {
    width: 200px;
    margin-bottom: 10px; }
  .infowindow_map .center {
    text-align: center;
    font-size: 1.125em;
    font-family: 'Montserrat-regular'; }
  .infowindow_map strong {
    font-family: 'Montserrat-SemiBold'; }

.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
  max-width: none !important; }

.map_contacts {
  position: relative; }
  .map_contacts #zone_bleue_area,
  .map_contacts #zone_orange_area,
  .map_contacts #zone_bleue_claire,
  .map_contacts #zone_orange_claire {
    cursor: pointer; }
  .map_contacts svg text {
    display: none; }
  .map_contacts .map_contacts_marker {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    max-width: 50%;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 12px;
    transition: all .4s ease-in-out; }
    .map_contacts .map_contacts_marker p {
      color: #ffffff; }
    .map_contacts .map_contacts_marker#zone_orange {
      top: 1rem;
      left: 1rem;
      background: #dc8803; }
      .map_contacts .map_contacts_marker#zone_orange a {
        color: #8b5602; }
      .map_contacts .map_contacts_marker#zone_orange:after {
        top: 100%;
        right: 1rem;
        border-width: 20px 10px 0 10px;
        border-color: #dc8803 transparent transparent transparent; }
    .map_contacts .map_contacts_marker#zone_bleue {
      bottom: 1rem;
      right: 1rem;
      background: #04648D; }
      .map_contacts .map_contacts_marker#zone_bleue a {
        color: #011e2a; }
      .map_contacts .map_contacts_marker#zone_bleue:after {
        bottom: 100%;
        left: 1rem;
        border-width: 0px 10px 20px 10px;
        border-color: transparent transparent #04648D transparent; }
    .map_contacts .map_contacts_marker#zone_grise {
      bottom: 1rem;
      left: 1rem;
      background: #989fa2; }
      .map_contacts .map_contacts_marker#zone_grise a {
        color: #656c6f; }
      .map_contacts .map_contacts_marker#zone_grise:after {
        bottom: 100%;
        right: 1rem;
        border-width: 0px 10px 20px 10px;
        border-color: transparent transparent #989fa2 transparent; }
    .map_contacts .map_contacts_marker.visible, .map_contacts .map_contacts_marker:hover {
      opacity: 1;
      visibility: visible; }

.map_contacts_legend figcaption {
  font-size: 0.875rem;
  font-style: italic; }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* DESKTOP : 1199px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLETP : 990px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLET : 768px px */
/* ----------------------------------------------------- */
@media (max-width: 768px) {
  .map_contacts .map_contacts_marker {
    max-width: 100%; } }

/* ----------------------------------------------------- */
/* TABLE : 640px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MOBILEP : 480px px */
/* ----------------------------------------------------- */
.timeline {
  margin: 4rem 0; }
  .timeline-dates {
    position: relative; }
    .timeline-dates:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: #333333; }
  .timeline-date {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
    margin: 2rem 0; }
    .timeline-date-inner {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch; }
    .timeline-date-image, .timeline-date-content {
      flex-basis: 50%;
      width: 50%; }
    .timeline-date-image img {
      width: 100%;
      height: auto; }
    .timeline-date .date {
      position: relative;
      font-size: 2em;
      font-weight: bold;
      background-color: #F2F2F2;
      padding: 1.5rem;
      text-align: right;
      font-family: "Montserrat-Bold", sans-serif; }
      .timeline-date .date:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #ffffff;
        border: 5px solid #F09403;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 30; }
    .timeline-date .titre {
      font-size: 1.25em;
      padding: 0.75rem 1.5rem;
      text-align: right;
      margin: 0; }
    .timeline-date .donnees {
      padding: 0 1.5rem;
      text-align: right;
      color: #F09403;
      font-style: italic;
      font-size: .875em; }
    .timeline-date .texte {
      padding: 1.5rem;
      text-align: right;
      font-size: 1.0em;
      margin-bottom: 0em; }
    .timeline-date.inverse {
      justify-content: flex-end; }
      .timeline-date.inverse .date {
        text-align: left; }
        .timeline-date.inverse .date:after {
          left: auto;
          right: 100%;
          -webkit-transform: translate(50%, -50%);
          -ms-transform: translate(50%, -50%);
          -o-transform: translate(50%, -50%);
          transform: translate(50%, -50%); }
      .timeline-date.inverse .titre,
      .timeline-date.inverse .texte,
      .timeline-date.inverse .donnees {
        text-align: left; }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* DESKTOP */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLETP */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLET */
/* ----------------------------------------------------- */
@media (max-width: 767px) {
  .timeline-dates {
    padding: 0 1rem; }
    .timeline-dates:after {
      left: 1rem; }
  .timeline-date-inner, .timeline-date.inverse-inner {
    flex-wrap: wrap; }
  .timeline-date-image, .timeline-date-content, .timeline-date.inverse-image, .timeline-date.inverse-content {
    flex-basis: 100%;
    width: 100%; }
  .timeline-date-image, .timeline-date.inverse-image {
    order: 2;
    padding: 1.5rem; }
  .timeline-date .date, .timeline-date.inverse .date {
    text-align: left; }
    .timeline-date .date:after, .timeline-date.inverse .date:after {
      left: 0;
      right: auto;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); } }

/* ----------------------------------------------------- */
/* MOBILE */
/* ----------------------------------------------------- */
@media (max-width: 479px) {
  .timeline-date, .timeline-date.inverse {
    position: relative;
    z-index: 32;
    background-color: #f3f3f3; }
    .timeline-date-inner, .timeline-date.inverse-inner {
      flex-wrap: wrap; }
    .timeline-date-image, .timeline-date-content, .timeline-date.inverse-image, .timeline-date.inverse-content {
      flex-basis: 100%; }
    .timeline-date .date, .timeline-date.inverse .date {
      text-align: center;
      padding: .5rem; }
    .timeline-date .titre, .timeline-date.inverse .titre {
      padding: 0;
      text-align: center; }
    .timeline-date .texte, .timeline-date.inverse .texte {
      padding: 0.5rem 0;
      text-align: center; } }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SECTIONS FLEXIBLES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
section {
  /* ----------------------------------------------------- */
  /* NEWS */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* SOCIAL WALL */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* TEXTE / TEXTE */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* TEXTE IMAGE TEXTE */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* TEXTE LARGE */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* IMAGE SEULE SUR TOUTE LA LARGEUR */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* GALERY */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* SLIDER */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* CONTACT */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* MULTI COLUMN */
  /* ----------------------------------------------------- */ }
  section .section_title {
    font-size: 3em;
    margin-bottom: 4vh;
    font-weight: normal;
    text-align: center; }
  section.news {
    position: relative;
    padding: 4vh 4vw; }
    section.news > .container {
      position: relative;
      z-index: 2; }
    section.news .section_title {
      text-align: center; }
    section.news .text {
      color: #000000;
      font-family: "Montserrat-Bold", sans-serif;
      text-align: center;
      text-transform: uppercase;
      padding: 0 2%; }
    section.news .news-container .news-item {
      position: relative;
      padding: 0;
      margin: 0 2% 2% 2%; }
      section.news .news-container .news-item-image {
        position: relative;
        z-index: 1; }
        section.news .news-container .news-item-image img {
          width: 100%;
          height: auto; }
      section.news .news-container .news-item-content {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        padding: 4%;
        color: #ffffff; }
        section.news .news-container .news-item-content .title {
          margin-bottom: 1rem; }
        section.news .news-container .news-item-content .excerpt p {
          color: #ffffff; }
        section.news .news-container .news-item-content .date {
          font-family: "Montserrat-Bold", sans-serif;
          font-size: 1em;
          font-weight: bold; }
    section.news .news-list {
      margin-top: 2em;
      text-align: center; }
  section.social_wall {
    padding: 4vh 4vw;
    position: relative; }
    section.social_wall .section_title {
      color: #ffffff; }
    section.social_wall .container {
      z-index: 10; }
    section.social_wall .tabs {
      float: right;
      list-style: none;
      margin: 0; }
      section.social_wall .tabs .tab {
        list-style: none;
        font-family: "Montserrat-Bold", sans-serif;
        font-size: 0.5em;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase; }
    section.social_wall .subtitle {
      float: right;
      font-family: "Montserrat-Bold", sans-serif;
      font-size: .5em;
      font-weight: bold;
      text-transform: uppercase; }
    section.social_wall .socialwall-list .col-1 .socialwall-item > a {
      height: calc(92vw / (12 / 1 + 1)); }
    section.social_wall .socialwall-list .col-2 .socialwall-item > a {
      height: calc(92vw / (12 / 2 + 1)); }
    section.social_wall .socialwall-list .col-3 .socialwall-item > a {
      height: calc(92vw / (12 / 3 + 1)); }
    section.social_wall .socialwall-list .col-4 .socialwall-item > a {
      height: calc(92vw / (12 / 4 + 1)); }
    section.social_wall .socialwall-list .col-5 .socialwall-item > a {
      height: calc(92vw / (12 / 5 + 1)); }
    section.social_wall .socialwall-list .col-6 .socialwall-item > a {
      height: calc(92vw / (12 / 6 + 1)); }
    section.social_wall .socialwall-list .col-7 .socialwall-item > a {
      height: calc(92vw / (12 / 7 + 1)); }
    section.social_wall .socialwall-list .col-8 .socialwall-item > a {
      height: calc(92vw / (12 / 8 + 1)); }
    section.social_wall .socialwall-list .col-9 .socialwall-item > a {
      height: calc(92vw / (12 / 9 + 1)); }
    section.social_wall .socialwall-list .col-10 .socialwall-item > a {
      height: calc(92vw / (12 / 10 + 1)); }
    section.social_wall .socialwall-list .col-11 .socialwall-item > a {
      height: calc(92vw / (12 / 11 + 1)); }
    section.social_wall .socialwall-list .col-12 .socialwall-item > a {
      height: calc(92vw / (12 / 12 + 1)); }
    section.social_wall .socialwall-item {
      margin: 2% 0; }
      section.social_wall .socialwall-item > a {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        color: #ffffff;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; }
      section.social_wall .socialwall-item .over {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;
        padding: 1em; }
  section.txt-txt {
    padding: 4vh 0; }
  section.txt-img, section.img-txt {
    padding: 4vh 0; }
    section.txt-img .txt, section.img-txt .txt {
      position: relative; }
      section.txt-img .txt .text p, section.img-txt .txt .text p {
        text-align: justify; }
  section.texte-large {
    padding: 4vh 0vw; }
  section.image_only {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 50vh;
    max-height: 60vh; }
    section.image_only img {
      display: none; }
    html.ios section.image_only {
      background: none; }
      html.ios section.image_only img {
        display: inline-block;
        width: 100%;
        height: auto; }
  section.gallery {
    margin: 4rem 0;
    height: 20vw;
    overflow: hidden; }
    section.gallery .container,
    section.gallery .container-fluid,
    section.gallery .row,
    section.gallery div[class^="col"] {
      height: 100%; }
    section.gallery div[class^="col"].no-padding {
      padding: 0; }
    section.gallery .image-container img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover; }
  section.slider {
    margin: 4rem 0; }
    section.slider .slider .item {
      padding: 0 1rem; }
      section.slider .slider .item img {
        margin: 0 auto; }
  section.contact {
    padding: 50px 0;
    background-color: #043447;
    position: relative; }
    section.contact .container {
      position: relative;
      z-index: 30; }
    section.contact .contact-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      background-image: url("../images/header-content.png");
      opacity: .25; }
    section.contact .title h2 {
      color: #F09403;
      text-transform: uppercase; }
    section.contact .text {
      text-align: center; }
      section.contact .text h3 {
        color: #ffffff;
        font-size: 1.1rem; }
      section.contact .text p {
        color: #ffffff;
        text-align: center; }
      section.contact .text a {
        color: #F09403; }
  section.multi-column .column-image {
    margin-bottom: 1rem; }

/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */
/* RESPONSIVE */
/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------- */
/* MOBILE */
/* ----------------------------------------------------- */
@media (min-width: 480px) {
  section.txt-img, section.img-txt {
    overflow: visible; }
    section.txt-img .img, section.img-txt .img {
      position: relative; }
  section.txt_img .txt,
  section.txt_img .img, section.img-txt .txt,
  section.img-txt .img {
    padding: 1rem; } }

/* ----------------------------------------------------- */
/* TABLET */
/* ----------------------------------------------------- */
@media (min-width: 767px) {
  section {
    /*  */
    /* ----------------------------------------------------- */ }
    section .section_title {
      text-align: left; }
    section.image_only {
      min-height: 80vh;
      max-height: 85vh; }
  /* ----------------------------------------------------- */
  /* HEIGHTS */
  /* ----------------------------------------------------- */ }

@media (min-width: 767px) and (max-height: 768px) {
  section.gallery {
    height: 15vw; } }

/* ----------------------------------------------------- */
/* TABLET PORTRAIT */
/* ----------------------------------------------------- */
@media (min-width: 1024px) {
  section .section_title {
    font-size: 4em;
    margin-bottom: 2rem; }
  section.txt-txt p img {
    display: inline-block;
    vertical-align: middle;
    width: 48%; } }

/* ----------------------------------------------------- */
/* DESKTOP */
/* ----------------------------------------------------- */
@media (min-width: 1200px) {
  section .section_title {
    font-size: 5em;
    margin-bottom: 3rem; } }

.slick-initialized .slick-slide {
  float: none !important;
  display: inline-block !important;
  vertical-align: middle;
  padding: 0.5rem !important; }

.form-std,
.page-template-template-contact form {
  background: #E7E7E7;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 30px 70px; }
  .form-std label,
  .page-template-template-contact form label {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 5px;
    width: 100%; }
  .form-std div.half,
  .page-template-template-contact form div.half {
    display: inline-block;
    vertical-align: top;
    width: 49.7%; }
  .form-std div:not(.half),
  .page-template-template-contact form div:not(.half) {
    display: inline-block;
    vertical-align: top;
    width: 100%; }
  .form-std div.wpcf7-display-none,
  .page-template-template-contact form div.wpcf7-display-none {
    display: none; }
  .form-std input,
  .form-std textarea,
  .page-template-template-contact form input,
  .page-template-template-contact form textarea {
    border: none;
    cursor: pointer; }
  .form-std input[type="text"],
  .form-std input[type="tel"],
  .form-std input[type="email"],
  .form-std textarea,
  .page-template-template-contact form input[type="text"],
  .page-template-template-contact form input[type="tel"],
  .page-template-template-contact form input[type="email"],
  .page-template-template-contact form textarea {
    border: 1px solid #DDD;
    margin-bottom: 15px;
    outline: none;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    transition: all 500ms; }
    .form-std input[type="text"]:focus,
    .form-std input[type="tel"]:focus,
    .form-std input[type="email"]:focus,
    .form-std textarea:focus,
    .page-template-template-contact form input[type="text"]:focus,
    .page-template-template-contact form input[type="tel"]:focus,
    .page-template-template-contact form input[type="email"]:focus,
    .page-template-template-contact form textarea:focus {
      border: 1px solid #999; }
  .form-std textarea,
  .page-template-template-contact form textarea {
    resize: vertical;
    height: 105px; }
  .form-std input[type=file],
  .page-template-template-contact form input[type=file] {
    margin-bottom: 15px; }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* DESKTOP :  */
/* ----------------------------------------------------- */
@media (max-width: 1199px) {
  .form-std form,
  .page-template-template-contact form {
    width: 100% !important;
    padding: 30px; } }

/* ----------------------------------------------------- */
/* TABLETP :  */
/* ----------------------------------------------------- */
@media (max-width: 990px) {
  .form-std form,
  .page-template-template-contact form {
    width: 100% !important; }
    .form-std form div.half,
    .page-template-template-contact form div.half {
      width: 49%; }
    .form-std form div:not(.half),
    .page-template-template-contact form div:not(.half) {
      width: 99%; } }

/* ----------------------------------------------------- */
/* TABLET :  */
/* ----------------------------------------------------- */
@media (max-width: 768px) {
  .form-std form,
  .page-template-template-contact form {
    background: #E7E7E7;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 30px; } }

/* ----------------------------------------------------- */
/* TABLE :  */
/* ----------------------------------------------------- */
@media (max-width: 640px) {
  .form-std form,
  .page-template-template-contact form {
    background: #E7E7E7;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 30px; }
    .form-std form div.half,
    .page-template-template-contact form div.half {
      width: 100%; } }

/* ----------------------------------------------------- */
/* MOBILEP :  */
/* ----------------------------------------------------- */
.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  /*margin-bottom: calc(1.5 * 1rem)*/
  margin: 2rem 0; }

.gallery-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;
  vertical-align: top;
  width: 100%; }
  .gallery-item:last-of-type {
    padding-right: 0; }
  .gallery-item > dt > a {
    display: block;
    line-height: 0;
    box-shadow: 0 0 0 0 transparent; }
    .gallery-item > dt > a:focus {
      box-shadow: 0 0 0 2px #0073aa; }

.gallery-caption {
  display: block;
  font-size: 0.71111em;
  font-family: "Montserrat-Bold", sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0.5rem; }

.image-container {
  height: 100%;
  display: flex;
  /*background-color: #fff;*/
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .image-container img {
    height: auto; }

#gallery-2 img {
  border: 2px solid #cfcfcf;
  width: 200px;
  height: 250px;
  object-fit: scale-down;
  background-color: white; }

#gallery-4 img {
  border: 2px solid #cfcfcf;
  width: 250px;
  height: 150px;
  object-fit: contain;
  background-color: white; }

#gallery-5 img {
  border: 2px solid #cfcfcf;
  height: 150px;
  width: 260px;
  object-fit: contain;
  background-color: white; }

#gallery-6 img {
  border: 2px solid #cfcfcf;
  object-fit: cover;
  height: 300px;
  width: 100%; }

#gallery-1 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-1 .gallery-item {
  max-width: calc((100% - 16px * 1) / 1); }
  .gallery-columns-1 .gallery-item:nth-of-type(1n+1) {
    margin-right: 0; }

#gallery-2 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-2 .gallery-item {
  max-width: calc((100% - 16px * 1) / 2); }
  .gallery-columns-2 .gallery-item:nth-of-type(2n+2) {
    margin-right: 0; }

#gallery-3 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-3 .gallery-item {
  max-width: calc((100% - 16px * 1) / 3); }
  .gallery-columns-3 .gallery-item:nth-of-type(3n+3) {
    margin-right: 0; }

#gallery-4 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-4 .gallery-item {
  max-width: calc((100% - 16px * 1) / 4); }
  .gallery-columns-4 .gallery-item:nth-of-type(4n+4) {
    margin-right: 0; }

#gallery-5 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-5 .gallery-item {
  max-width: calc((100% - 16px * 1) / 5); }
  .gallery-columns-5 .gallery-item:nth-of-type(5n+5) {
    margin-right: 0; }

#gallery-6 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-6 .gallery-item {
  max-width: calc((100% - 16px * 1) / 6); }
  .gallery-columns-6 .gallery-item:nth-of-type(6n+6) {
    margin-right: 0; }

#gallery-7 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-7 .gallery-item {
  max-width: calc((100% - 16px * 1) / 7); }
  .gallery-columns-7 .gallery-item:nth-of-type(7n+7) {
    margin-right: 0; }

#gallery-8 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-8 .gallery-item {
  max-width: calc((100% - 16px * 1) / 8); }
  .gallery-columns-8 .gallery-item:nth-of-type(8n+8) {
    margin-right: 0; }

#gallery-9 img {
  width: 100%;
  border: none !important;
  object-fit: scale-down;
  background-color: white; }

.gallery-columns-9 .gallery-item {
  max-width: calc((100% - 16px * 1) / 9); }
  .gallery-columns-9 .gallery-item:nth-of-type(9n+9) {
    margin-right: 0; }

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* DESKTOP : 1199px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* TABLETP : 990px px */
/* ----------------------------------------------------- */
@media (max-width: 990px) {
  .gallery-columns-5 .gallery-item {
    max-width: 39% !important;
    width: auto !important;
    float: none !important; }
  .gallery-columns-4 .gallery-item {
    max-width: 50% !important;
    width: auto !important; } }

/* ----------------------------------------------------- */
/* TABLET : 768px px */
/* ----------------------------------------------------- */
@media (max-width: 768px) {
  #gallery-2 img {
    width: 200px; }
  .gallery-columns-2 .gallery-item {
    max-width: 100% !important;
    width: auto !important; }
  .gallery-columns-5 .gallery-item {
    /*max-width: calc((100% - -68px * 4) / 5)!important;*/
    max-width: 100% !important;
    width: auto !important; } }

/* ----------------------------------------------------- */
/* TABLE : 640px px */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* MOBILEP : 480px px */
/* ----------------------------------------------------- */
