.form-std,
.page-template-template-contact form
{
    background: #E7E7E7;
    margin: 0 auto;
    margin-bottom: 15px;
    padding: 30px 70px;

    label {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 5px;
        width: 100%;
    }

    div {
        &.half {
            display: inline-block;
            vertical-align: top;
            width: 49.7%;
        }

        &:not(.half) {
            display: inline-block;
            vertical-align: top;
            width: 100%;
        }

        &.wpcf7-display-none {
            display: none;
        }
    }

    input,
    textarea {
        border: none;
        cursor: pointer;
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
        border: 1px solid #DDD;
        margin-bottom: 15px;
        outline: none;
        width: 100%;
        height: 35px;
        padding-left: 15px;
        transition: all 500ms;

        &:focus {
            border: 1px solid #999;
        }
    }

    textarea {
        resize: vertical;
        height: 105px;
    }

    input[type=file] {
        margin-bottom: 15px;
    }

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP :  */
/* ----------------------------------------------------- */
@media (max-width: $screen-lg) {

    .form-std,
    .page-template-template-contact {

        form {
            width: 100% !important;
            padding: 30px;
        }
    }

}


/* ----------------------------------------------------- */
/* TABLETP :  */
/* ----------------------------------------------------- */
@media (max-width: $screen-md) {
    
    .form-std,
    .page-template-template-contact {
        form {
            width: 100% !important;

            div.half {
                width: 49%;
            }
        
            div:not(.half) {
                width: 99%;
            }
        }
    }

}

/* ----------------------------------------------------- */
/* TABLET :  */
/* ----------------------------------------------------- */
@media (max-width: $screen-sm) {
    
    .form-std,
    .page-template-template-contact {
        form {
            background: #E7E7E7;
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            padding: 30px;
        }
    }

}

/* ----------------------------------------------------- */
/* TABLE :  */
/* ----------------------------------------------------- */
@media (max-width: $screen-xs) {
    
    .form-std,
    .page-template-template-contact {
        form {
            background: #E7E7E7;
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            padding: 30px;

            div.half {
                width: 100%;
            }
        }
    }

}

/* ----------------------------------------------------- */
/* MOBILEP :  */
/* ----------------------------------------------------- */
@media (max-width: $screen-xxs) {
    
}