/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* SECTIONS FLEXIBLES */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

section {
	.section_title {
		font-size: 3em;
		margin-bottom: 4vh;
		font-weight: normal;
		text-align: center; }


	/* ----------------------------------------------------- */
	/* NEWS */
	/* ----------------------------------------------------- */
	&.news {
		position: relative;
		padding: 4vh 4vw;


		> .container {
			position: relative;
			z-index: 2; }

		.section_title {
			text-align: center; }

		.text {
			color: $black;
			font-family: $ff1;
			text-align: center;
			text-transform: uppercase;
			padding: 0 2%; }

		.news-container {
			.news-item {
				position: relative;
				padding: 0;
				margin: 0 2% 2% 2%;

				&-image {
					position: relative;
					z-index: 1;

					img {
						width: 100%;
						height: auto; } }

				&-content {
					position: absolute;
					z-index: 2;
					bottom: 0;
					left: 0;
					right: 0;
					top: auto;
					padding: 4%;
					color: $white;


					.title {
						margin-bottom: 1rem; }

					.excerpt {

						p {
							color: $white; } }

					.date {
						font-family: $ff1;
						font-size: 1em;
						font-weight: bold; } } } }

		.news-list {
			margin-top: 2em;
			text-align: center; } }

	/* ----------------------------------------------------- */
	/* SOCIAL WALL */
	/* ----------------------------------------------------- */
	&.social_wall {
		padding: 4vh 4vw;
		position: relative;

		.section_title {
			color: $white; }



		.container {
			z-index: 10; }

		.tabs {
			float: right;
			list-style: none;
			margin: 0;

			.tab {
				list-style: none;
				font-family: $ff1;
				font-size: 0.5em;
				font-weight: bold;
				color: $white;
				text-transform: uppercase; } }

		.subtitle {
			float: right;
			font-family: $ff1;
			font-size: .5em;
			font-weight: bold;
			text-transform: uppercase; }

		.socialwall {
			&-list {

				@for $i from 1 through 12 {
					.col-#{$i} {
						.socialwall-item {
							> a {
								height: calc(92vw / (12 / #{$i} + 1) ); } } } } }

			&-item {
				margin: 2% 0;

				> a {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					color: $white;
					-webkit-background-size: cover;
					-moz-background-size: cover;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center; }


				.over {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.6);
					opacity: 0;
					visibility: hidden;
					-webkit-transition: $trans;
					-moz-transition: $trans;
					-ms-transition: $trans;
					-o-transition: $trans;
					transition: $trans;
					padding: 1em; } } } }


	/* ----------------------------------------------------- */
	/* TEXTE / TEXTE */
	/* ----------------------------------------------------- */
	&.txt-txt {
		padding: 4vh 0; }

	/* ----------------------------------------------------- */
	/* TEXTE IMAGE TEXTE */
	/* ----------------------------------------------------- */
	&.txt-img,
	&.img-txt {
		padding: 4vh 0;

		.txt {
			position: relative;
			// padding-right: calc(100% / 12)

			.text {

				p {
					text-align: justify; } } } }

	/* ----------------------------------------------------- */
	/* TEXTE LARGE */
	/* ----------------------------------------------------- */
	&.texte-large {
		padding: 4vh 0vw; }

	/* ----------------------------------------------------- */
	/* IMAGE SEULE SUR TOUTE LA LARGEUR */
	/* ----------------------------------------------------- */
	&.image_only {
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		min-height: 50vh;
		max-height: 60vh;

		img {
			display: none; }

		html.ios & {
			background: none;

			img {
				display: inline-block;
				width: 100%;
				height: auto; } } }

	/* ----------------------------------------------------- */
	/* GALERY */
	/* ----------------------------------------------------- */
	&.gallery {
		margin: 4rem 0;
		height: 20vw;
		overflow: hidden;

		// &:last-child
		// 	margin-bottom: 0

		.container,
		.container-fluid,
		.row,
		div[class^="col"] {
			height: 100%; }

		div[class^="col"] {
			&.no-padding {
				padding: 0; } }

		.image-container {
			img {
				width: 100%;
				height: 100%;
				object-position: center;
				object-fit: cover; } } }

	/* ----------------------------------------------------- */
	/* SLIDER */
	/* ----------------------------------------------------- */
	&.slider {
		margin: 4rem 0;

		.slider {
			.item {
				padding: 0 1rem;

				img {
					margin: 0 auto; } } } }


	/* ----------------------------------------------------- */
	/* CONTACT */
	/* ----------------------------------------------------- */
	&.contact {
		padding: 50px 0;
		background-color: $dark-blue-green;
		position: relative;

		.container {
			position: relative;
			z-index: 30; }

		.contact-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 20;
			background-image: url('../images/header-content.png');
			opacity: .25; }

		.title {
			h2 {
				color: $orange;
				text-transform: uppercase; } }

		.text {
			text-align: center;

			h3 {
				color: $white;
				font-size: 1.1rem; }

			p {
				color: $white;
				text-align: center; }

			a {
				color: $orange; } } }


	/* ----------------------------------------------------- */
	/* MULTI COLUMN */
	/* ----------------------------------------------------- */
	&.multi-column {
		.column {
			&-image {
				margin-bottom: 1rem; } } } }

/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */
/* RESPONSIVE */
/* ---------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------- */


/* ----------------------------------------------------- */
/* MOBILE */
/* ----------------------------------------------------- */
@media (min-width: 480px) {

	section {

		&.txt-img,
		&.img-txt {
			overflow: visible;

			.img {
				position: relative; } }

		&.txt_img,
		&.img-txt {
			.txt,
			.img {
				padding: 1rem; } } } }

/* ----------------------------------------------------- */
/* TABLET */
/* ----------------------------------------------------- */
@media (min-width: 767px) {

	section {

		.section_title {
			text-align: left; }


		/*  */
		/* ----------------------------------------------------- */
		&.image_only {
			min-height: 80vh;
			max-height: 85vh; } }

	/* ----------------------------------------------------- */
	/* HEIGHTS */
	/* ----------------------------------------------------- */
	@media (max-height: 768px) {
		section.gallery {
			height: 15vw; } } }


/* ----------------------------------------------------- */
/* TABLET PORTRAIT */
/* ----------------------------------------------------- */
@media (min-width: 1024px) {

	section {
		.section_title {
			font-size: 4em;
			margin-bottom: 2rem; }


		&.txt-txt {
			p {
				img {
					display: inline-block;
					vertical-align: middle;
					width: 48%; } } } } }


/* ----------------------------------------------------- */
/* DESKTOP */
/* ----------------------------------------------------- */
@media (min-width: 1200px) {

	section {
		.section_title {
			font-size: 5em;
			margin-bottom: 3rem; } } }



