.action-button {
    background-color: $orange;
    color: $white;
    padding: 10px 40px;
    border-radius: 20px;
    transition: all 0.2s;
    text-decoration: none;
    margin: 15px 0;
    text-transform: uppercase;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 0.8em;

    &:hover {
        background-color: $dark-grey;
        color: $white;
        text-decoration: none;
        transition: all 0.2s;
    }

    &.carre {
        border-radius: 0px;
        font-size: 1em;
        padding: 15px 20px;

        &:before {
            content: "\e907";
            color: $white;
            font-family: 'Icomoon';
            margin-right: 15px;
            font-size: 1em;
            padding: 0;
        }
    }
}

main {
    background-color: $background-main;

    p {
        color: $dark-grey;
        font-size: 0.95em;
    }
}

img {
    &.aligncenter {
        display: block;
        margin: 0 auto;
    }
}