.wrap-3-pictures {
    padding: 30px 15px;
    background-color: $dark-blue-green;

    .image {
        position: relative;
        height: 235px;

        .linear-gradient {
            position: relative;
            width: 100%;
            height: 100%;
            background: -moz-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#07648d', endColorstr='#007db9e8', GradientType=1);
            /* IE6-9 */
        }
    }

    .content-txt {
        position: absolute;
        top: 0;
        left: 0;
        padding: 50px 30px;
        text-align: center;

        h2 {
            color: white;
            font-family: 'Montserrat-ExtraBold', sans-serif;
            text-transform: uppercase;
            font-size: 1.4em;
        }

        p {
            font-family: 'Montserrat-Bold', sans-serif;
            font-size: 1em;
            color: orange;
            text-transform: uppercase;
        }
    }
}

.wrap-edito {
    .no-padding-left {
        padding-left: 50px;
    }

    .flex-center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;

        h3 {
            color: $dark-blue-green;
            font-family: 'Montserrat-Bold', sans-serif;
            font-size: 1.75em;
            text-transform: uppercase;
            display: inline;
        }

        .separator {
            content: "";
            background-color: $dark-blue-green;
            width: 80%;
            height: 1px;
        }
    }

    p {
        text-align: justify;
        color: $dark-grey;
        font-size: 1em;
        font-family: 'Montserrat-Regular', sans-serif;
        margin: 35px 0 50px 0;
    }

    .orange {
        color: orange;
        text-decoration: none;
        transition: all 0.2s;
        cursor: pointer;
        font-weight: 900;

        &:hover {
            color: $dark-grey;
            transition: all 0.2s;
        }
    }

    .images-col-6 {
        padding-right: 0;

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
}

.wrap-actus {
    padding: 50px 0;

    .flex {
        display: flex;
        align-items: center;

        h3 {
            font-family: 'Montserrat-Bold', sans-serif;
            font-size: 1.75em;
            text-transform: uppercase;
            text-align: center;
        }

        .separator {
            content: "";
            background-color: $dark-blue-green;
            width: 100%;
            height: 1px;
        }
    }

    .item {
        margin-top: 50px;

        img {
            width: 100%;
            height: 247px;
            object-fit: cover;
            padding-bottom: 20px;
        }

        .date {
            color: orange;
            font-family: 'Montserrat-Black', sans-serif;
            font-size: 1.4em;
            margin: 0;
            padding: 0;
            line-height: 1;
        }

        .title {
            display: flex;
            flex-direction: column;
            padding-left: 20px;

            h4 {
                font-family: 'Montserrat-Bold', sans-serif;
                font-size: 1.25em;
                text-transform: uppercase;
                color: $dark-blue-green;
                margin: 0;
            }
        }

        p {
            color: $dark-grey;
            font-size: 1em;
            font-family: 'Montserrat-Regular', sans-serif;
            padding: 20px 0;
        }
    }
}

.wrap-job-dl {
    // margin-top: 50px;

    &-container {
        width: 100%;
    }

    &-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch;
    }





    .left,
    .right {
        padding: 70px 50px;
        color: $white;
    }

    h4 {
        font-family: 'Monserrat-Bold', sans-serif;
        font-size: 1.75em;
        text-transform: uppercase;
    }

    p {
        padding: 25px 0;
    }

    .separator {
        content: "";
        background-color: $white;
        width: 70%;
        height: 1px;
    }

    .left {
        text-align: right;

        h4 {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:before {
                content: "";
                background-color: $white;
                width: 35%;
                height: 1px;
                display: block;
                margin-right: 30px;
            }
        }
    }

    .right {
        text-align: left;

        h4 {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:after {
                content: "";
                background-color: $white;
                width: 35%;
                height: 1px;
                display: block;
                margin-left: 30px;
            }
        }
    }
}

.wrap-3-pictures {
    padding: 30px 15px;
    background-color: $dark-blue-green;

    .image {
        position: relative;
        height: 235px;

        .linear-gradient {
            position: relative;
            width: 100%;
            height: 100%;
            background: -moz-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(7, 100, 141, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#07648d', endColorstr='#007db9e8', GradientType=1);
            /* IE6-9 */
        }
    }

    .content-txt {
        position: absolute;
        top: 0;
        left: 0;
        padding: 50px 30px;
        text-align: center;

        h2 {
            color: white;
            font-family: 'Montserrat-ExtraBold', sans-serif;
            text-transform: uppercase;
            font-size: 1.4em;
        }

        p {
            font-family: 'Montserrat-Bold', sans-serif;
            font-size: 1em;
            color: orange;
            text-transform: uppercase;
        }
    }
}

.wrap-edito {
    .no-padding-left {
        padding-left: 50px;
    }

    .flex-center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;

        h3 {
            color: $dark-blue-green;
            font-family: 'Montserrat-Bold', sans-serif;
            font-size: 1.75em;
            text-transform: uppercase;
            display: inline;
        }

        .separator {
            content: "";
            background-color: $dark-blue-green;
            width: 80%;
            height: 1px;
        }
    }

    p {
        text-align: justify;
        color: $dark-grey;
        font-size: 1em;
        font-family: 'Montserrat-Regular', sans-serif;
        margin: 35px 0 50px 0;
    }

    .orange {
        color: orange;
        text-decoration: none;
        transition: all 0.2s;
        cursor: pointer;
        font-weight: 900;

        &:hover {
            color: $dark-grey;
            transition: all 0.2s;
        }
    }

    .images-col-6 {
        padding-right: 0;

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
}

.wrap-actus {
    padding: 50px 0;

    .flex {
        display: flex;
        align-items: center;

        h3 {
            font-family: 'Montserrat-Bold', sans-serif;
            font-size: 1.75em;
            text-transform: uppercase;
            text-align: center;
        }

        .separator {
            content: "";
            background-color: $dark-blue-green;
            width: 100%;
            height: 1px;
        }
    }

    .item {
        margin-top: 50px;

        img {
            width: 100%;
            height: 247px;
            object-fit: cover;
            padding-bottom: 20px;
        }

        .date {
            color: orange;
            font-family: 'Montserrat-Black', sans-serif;
            font-size: 1.4em;
            margin: 0;
            padding: 0;
            line-height: 1;
        }

        .title {
            display: flex;
            flex-direction: column;
            padding-left: 20px;

            h4 {
                font-family: 'Montserrat-Bold', sans-serif;
                font-size: 1.25em;
                text-transform: uppercase;
                color: $dark-blue-green;
                margin: 0;
            }
        }

        p {
            color: $dark-grey;
            font-size: 1em;
            font-family: 'Montserrat-Regular', sans-serif;
            padding: 20px 0;
        }
    }
}

.wrap-job-dl {
    // margin-top: 50px;

    .left,
    .right {
        padding: 70px 50px;
        color: $white;
    }

    h4 {
        font-family: 'Monserrat-Bold', sans-serif;
        font-size: 1.75em;
        text-transform: uppercase;
    }

    p {
        padding: 25px 0;
    }

    .separator {
        content: "";
        background-color: $white;
        width: 70%;
        height: 1px;
    }

    .left {
        text-align: right;

        h4 {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:before {
                content: "";
                background-color: $white;
                width: 35%;
                height: 1px;
                display: block;
                margin-right: 30px;
            }
        }
    }

    .right {
        text-align: left;

        h4 {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:after {
                content: "";
                background-color: $white;
                width: 35%;
                height: 1px;
                display: block;
                margin-left: 30px;
            }
        }
    }
}

@media (max-width : 1199.99px) {
    .wrap-3-pictures {
        .image {
            height: 192px;
        }
    }

    .wrap-edito {
        .no-padding-left {
            padding-left: 15px;
        }

        .flex-center {
            .separator {
                width: 60%;
            }
        }

        .images-col-6 {
            padding-right: 0;
        }
    }

    .wrap-job-dl {

        .left,
        .right {
            padding: 60px 50px;
        }
    }
}

@media (max-width : 991.99px) {
    .wrap-3-pictures {
        padding: 30px 15px;

        .image {
            height: 175px;
            margin-bottom: 15px;
        }

        .content-txt {
            padding: 40px 10px;

            h2 {
                font-size: 1.2em;
            }
        }
    }
}

@media (max-width : 767.99px) {
    .wrap-3-pictures {
        padding: 30px 15px;

        .image {
            height: 175px;
            margin-bottom: 15px;
        }

        .content-txt {
            padding: 50px 15px;
            width: 100%;

            h2 {
                font-size: 1.2em;
            }
        }
    }

    .wrap-edito {
        .no-padding-left {
            padding-left: 15px;
        }

        .flex-center {
            padding-top: 50px;

            h3 {
                font-size: 1.5em;
            }

            .separator {
                width: 60%;
            }
        }

        .images-col-6 {
            margin-top: 30px;
        }
    }

    .wrap-actus {
        .flex {
            h3 {
                font-size: 1.5em;
            }
        }
    }

    .wrap-job-dl {
        // margin-top: 50px;

        .wrap-job-dl-row {
            flex-wrap: wrap;
        }

        .left,
        .right {
            padding: 60px 50px;
        }

        h4 {
            font-size: 1.5em;
        }
    }
}

@media screen and (max-width: 480px) {
    
    .wrap-job-dl-row {
        flex-wrap: wrap;
    }

}