#map {
	width: 100%;
	height: 730px;
}

.infowindow_map {
	width: 230px;

	img {
		width: 200px;
		margin-bottom: 10px;
	}

	.center {
		text-align: center;
		font-size: 1.125em;
		font-family: 'Montserrat-regular';
	}

	strong{
		font-family: 'Montserrat-SemiBold';
	}
}

.gm-style {
	.gm-style-iw-c,
	.gm-style-iw-d {
		max-width: none !important;
	}
}

.map_contacts {
	position: relative;

	#zone_bleue_area,
	#zone_orange_area,
	#zone_bleue_claire,
	#zone_orange_claire {
		cursor: pointer;
	}

	

	svg {
		text {
			display: none;
		}
	}

	.map_contacts_marker {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		max-width: 50%;
		padding: 1rem;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0,0,0,0.1);
		color: white;
		font-size: 12px;
		transition: all .4s ease-in-out;

		// &:after {
		// 	content: "";
		// 	display: block;
		// 	position: absolute;
		// 	width: 0;
		// 	height: 0;
		// 	width: 0;
		// 	border-style: solid;
		// }

		p {
			color: $white;
		}
		
		&#zone_orange {
			top: 1rem;
			left: 1rem;
			background: darken($orange, 4%);

			a {
				color: darken($orange, 20%);
			}

			&:after {
				top: 100%;
				right: 1rem;
				border-width: 20px 10px 0 10px;
				border-color: darken($orange, 4%) transparent transparent transparent;

			}
		}
		
		&#zone_bleue {
			bottom: 1rem;
			right: 1rem;
			background: $blue;

			a {
				color: darken($blue, 20%);
			}

			&:after {
				bottom: 100%;
				left: 1rem;
				border-width: 0px 10px 20px 10px;
				border-color: transparent transparent $blue transparent;
			}
		}
		
		&#zone_grise {
			bottom: 1rem;
			left: 1rem;
			background: #989fa2;

			a {
				color: darken(#989fa2, 20%);
			}

			&:after {
				bottom: 100%;
				right: 1rem;
				border-width: 0px 10px 20px 10px;
				border-color: transparent transparent #989fa2 transparent;
			}
		}

		&.visible,
		&:hover {
			opacity: 1;
			visibility: visible;
		}
	}

}

.map_contacts_legend {
	figcaption {
		font-size: 0.875rem;
		font-style: italic;
	}
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-lg) {
	
}


/* ----------------------------------------------------- */
/* TABLETP : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-md) {
	
}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-sm) {

	.map_contacts {
		.map_contacts_marker {
			max-width: 100%;
		}	
	}

}

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xs) {
	
}

/* ----------------------------------------------------- */
/* MOBILEP : #{$screen-xxs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xxs) {
	
}