.gallery{
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/*margin-bottom: calc(1.5 * 1rem)*/
	margin: 2rem 0;
}

.gallery-item{
	display: inline-block;
	margin-right: 16px;
	margin-bottom: 16px;
	text-align: center;
	vertical-align: top;
	width: 100%;

	&:last-of-type {
		padding-right: 0;
	}

	> dt {
		> a {
			display: block;
			line-height: 0;
			box-shadow: 0 0 0 0 transparent;

			&:focus {
				box-shadow: 0 0 0 2px #0073aa;
			}
		}
	}

}

.gallery-caption {
	display: block;
	font-size: 0.71111em;
	font-family: $ff1;
	line-height: 1.6;
	margin: 0;
	padding: 0.5rem;
}

.image-container {
	height: 100%;
	display: flex;
	/*background-color: #fff;*/
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		height: auto;
	}
}

#gallery-2 img {
    border: 2px solid #cfcfcf;
    width: 200px;
    height: 250px;
    object-fit: scale-down;
    background-color: white;
}

#gallery-4 img {
    border: 2px solid #cfcfcf;
    width: 250px;
    height: 150px;
    object-fit: contain;
    background-color: white;
}
#gallery-5 img {
    border: 2px solid #cfcfcf;
    height: 150px;
    width: 260px;
    object-fit: contain;
    background-color: white;
}
#gallery-6 img {
    border: 2px solid #cfcfcf;
    object-fit: cover;
    height: 300px;
    width: 100%;
}

@for $i from 1 through 9 {

	#gallery-#{$i} img {
		width: 100%;
		border: none !important;
		object-fit: scale-down;
		background-color: white;
	}

	.gallery-columns-#{$i} {
		.gallery-item{
			max-width: calc((100% - 16px * 1) / #{$i});

			&:nth-of-type(#{$i}n+#{$i}) {
				margin-right: 0
			}
		}
	}

}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-lg) {
	
}


/* ----------------------------------------------------- */
/* TABLETP : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-md) {
	.gallery-columns-5 .gallery-item {
		max-width: 39%!important;
		width: auto!important;
		float: none!important;
	}
	.gallery-columns-4 .gallery-item {
		max-width: 50%!important;
		width: auto!important;
	}
}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-sm) {
	#gallery-2 img{
		width: 200px;
	}

	.gallery-columns-2 .gallery-item {
		max-width: 100%!important;
		width: auto!important;
	}

	.gallery-columns-5 .gallery-item {
		/*max-width: calc((100% - -68px * 4) / 5)!important;*/
		max-width: 100%!important;
		width: auto!important;
	}
}

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xs) {
	
}

/* ----------------------------------------------------- */
/* MOBILEP : #{$screen-xxs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xxs) {
	
}



