.navbar-nav {
    > li {
        min-width: 130px;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        > a {
            text-transform: uppercase;
            font-family: 'Montserrat-SemiBold';
            font-size: 0.85em;
            position: relative;
            color: #333333;
            padding: 10px 15px;
            width: 100%;
            text-align: center;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
        }

        &.current-menu-item,
        &:hover {
            background-color: $orange;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;

            > a {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }
}

.header-home,
.header-content {
    position: relative;

    .wrap-header {
        background-size: contain;
        background-position: center;
        width: 100%;
        height: auto;
        padding: 15px 0;

        > .container {
            z-index: 1001;
        }

        h1 {
            font-family: 'Montserrat-regular';

            strong {
                font-family: 'Montserrat-bold'!important;
            }
        }

        .navbar {
            .space-between {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .logo {
                    padding: 0;
                }

                .nav-right {
                    padding: 0;

                    .link-nav {
                        display: inline-flex;
                        float: right;
                    }
                }

                .navbar-brand {
                    img {
                        width: 250px;
                        height: auto;
                    }
                }

                .link-nav {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    .icon {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &::before {
                            font-size: 1.2em;
                        }

                        // &:after {
                        //     content: "";
                        //     color: $white;
                        //     margin: 14px;
                        //     width: 1px;
                        //     height: 15px;
                        //     background-color: $white;
                        //     display: flex;
                        // }
                    }

                    .language {
                        position: relative;
                        cursor: pointer;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 9px;
                            right: 0px;
                            border-top: 8px solid $white;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            z-index: 1;
                        }

                        select {
                            background: transparent;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            border-radius: 0;
                            border: 0;
                            padding-right: 30px;
                            font-family: 'Lato-Black', sans-serif;
                            color: $white;
                            z-index: 2;
                            position: relative;
                            text-transform: uppercase;
                            

                            option {
                                color: $dark-grey;
                            }
                        }
                    }
                }
            }

            .navbar-collapse {
                padding-top: 30px;

                .navbar-nav {
                    background-color: $white;

                    .nav-item {
                        min-width: 130px;
                        text-align: center;
                        display: flex;
                        align-items: center;

                        &.active,
                        &:hover {
                            background-color: $orange;
                            transition: all 0.2s;

                            .nav-link {
                                color: $white;
                            }
                        }

                        .nav-link {
                            text-transform: uppercase;
                            font-family: 'Montserrat-SemiBold';
                            font-size: 0.85em;
                            position: relative;
                            color: $dark-grey;
                            padding: 10px 15px;
                            width: 100%;
                            text-align: center;
                            transition: all 0.2s;
                        }
                    }
                }
            }
        }

        h1 {
            padding: 120px 15px;
            font-family: 'Montserrat-Bold', sans-serif;
            font-size: 2.6em;
            color: $white;
            text-align: center;

            span {
                font-family: 'Montserrat-Regular', sans-serif;
            }
        }
    }
}

.header-content {
    .wrap-header {
        padding: 0;
        position: relative;
        height: 100%;

        .navbar {
            bottom: 0;
            padding: 30px 0px;
            background-color: rgba($color: $blue, $alpha: 0.5);
            width: 100%;

            .background-nav {
                display: block;
                margin: auto;

                .space-between {
                    padding: 0 15px;
                }
            }
        }
    }
}

.wrap-header {
    &.page-interieur {
        &:after {
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(4, 100, 141, 0.5);
        }
    }

    > .container {
        position: relative;
        z-index:1;
    }
}

@media (max-width : 1199.99px) {

    .header-home,
    .header-content {
        .wrap-header {
            .navbar {
                .navbar-collapse {
                    .navbar-nav {
                        .nav-item {
                            min-width: initial;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width : 991.99px) {

    .header-home,
    .header-content {
        &.header-content {
            height: initial;

            .navbar {
                width: 100%;
                z-index: 15;
                position: relative;
            }
        }

        .wrap-header {
            h1 {
                padding: 40px 15px 10px 15px;
                font-size: 1.8em;
            }

            .navbar {
                .background-nav {
                    width: 100%;
                }

                .space-between {
                    .navbar-brand {
                        img {
                            width: 200px;
                        }
                    }
                }

                .nav-right {
                    .link-nav {
                        display: none;
                    }
                }

                .navbar-collapse {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 0;
                    height: auto !important;

                    .navbar-nav {
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                    }

                    .nav-link {
                        text-align: center;

                        .nav-item {
                            min-width: initial;
                        }
                    }
                }
            }

            .navbar-toggler {
                position: fixed;

                width: 40px;
                height: 40px;
                padding: 10px;

                z-index: 1002;
                bottom: 0px;
                right: 0px;
                border: none;
                border-radius: 0px;
                background: $white;

                .icon-bar {
                    width: 20px;
                    height: 2px;
                    background-color: #B6B6B6;
                    display: block;
                    transition: all 0.2s;
                    margin-top: 4px;

                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }

                    &.middle-bar {
                        opacity: 0;
                    }

                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                    }
                }

                &.collapsed {
                    .top-bar {
                        transform: rotate(0);
                    }

                    .middle-bar {
                        opacity: 1;
                    }

                    .bottom-bar {
                        transform: rotate(0);
                    }
                }
            }
        }
    }
}