footer {
    background-color: $dark-blue-green;
    padding: 50px 0;

    .top-footer {
        display: flex;
        flex-direction: row;

        img {
            width: 100%;
            height: auto;
            min-width: 150px;
        }

        .social-media {
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            height: 100%;

            a {
                text-decoration: none!important;
            }

            .icon-circle-fb {
                margin-right: 5px;
            }

            .icon {
                &:before {
                    font-size: 3em;
                }
            }
        }
    }

    .bottom-footer {
        padding-top: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .adress {
            text-align: center;
            min-width: 200px;
            flex-basis: 33%;
            margin-bottom: 2rem;

            h5 {
                font-family: 'Montserrat-Bold', sans-serif;
                font-size: 1em;
                color: $orange;
            }

            p,
            a {
                font-family: 'Montserrat-Regular', sans-serif;
                font-size: 0.8em;
                color: $middle-green;
                margin-bottom: 0;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }

                &.adress-txt {
                    margin-bottom: 15px;
                }
            }
        }

        .link-bottom {
            flex-basis: 100%;
            text-align: right;
            margin: 30px 0 0;
            font-family: 'Montserrat-Regular', sans-serif;
            font-size: 0.8em;
            color: #849FAF;
            width: 100%;

            a,
            span {
                font-size: .875rem;
                color: $white;
            }
        }
    }
}

@media (max-width : 575.99px) {
    footer {
        padding: 50px 0;

        .top-footer {
            display: flex;
            flex-direction: row;
            justify-content :center;

            img {
                width: 100%;
                height: auto;
                min-width : 100px;
            }

            .social-media {
                display: flex;
                justify-content: space-around;
                align-items: flex-end;
                height: 100%;

                .icon {
                    &:before {
                        font-size: 3em;
                    }
                }
            }
        }

        .bottom-footer {
            padding-top: 50px;
            // flex-direction: column;

            .adress {
                text-align: center;
                min-width: 200px;
                margin-top: 30px;

                h5 {
                    font-family: 'Montserrat-Bold', sans-serif;
                    font-size: 1em;
                    color: $orange;
                }

                p,
                a {
                    font-family: 'Montserrat-Regular', sans-serif;
                    font-size: 0.8em;
                    color: $middle-green;
                    margin-bottom: 0;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }

                    &.adress-txt {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP : #{$screen-lg} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-lg) {
    
}


/* ----------------------------------------------------- */
/* TABLETP : #{$screen-md} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-md) {
    
}

/* ----------------------------------------------------- */
/* TABLET : #{$screen-sm} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-sm) {
    
}

/* ----------------------------------------------------- */
/* TABLE : #{$screen-xs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xs) {
    
}

/* ----------------------------------------------------- */
/* MOBILEP : #{$screen-xxs} px */
/* ----------------------------------------------------- */
@media (max-width: $screen-xxs) {
    
    footer {
        .bottom-footer {
            .adress {
                flex-basis: 100%;
                min-width: 0px;
            }
        }
    }

}