@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Montserrat-Italic';
  src: url('./fonts/Montserrat/Montserrat-Italic.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/Montserrat/Montserrat-Italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./fonts/Montserrat/Montserrat-ExtraBold.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat/Montserrat-Bold.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat-Black';
  src: url('./fonts/Montserrat/Montserrat-Black.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/Montserrat/Montserrat-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Lato-Black';
  src: url('./fonts/Lato/Lato-Black.ttf');
  /* IE9 Compat Modes */
  src: url('./fonts/Lato/Lato-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/Icomoon/icomoon.eot?hzbnj5');
  src:  url('fonts/Icomoon/icomoon.eot?hzbnj5#iefix') format('embedded-opentype'), url('fonts/Icomoon/icomoon.woff2?hzbnj5') format('woff2'), url('fonts/Icomoon/icomoon.ttf?hzbnj5') format('truetype'), url('fonts/Icomoon/icomoon.woff?hzbnj5') format('woff'), url('fonts/Icomoon/icomoon.svg?hzbnj5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dl:before {
  content: "\e908";
  color: #fff;
}
.icon-full-mail:before {
  content: "\e907";
  color: #fff;
}
.icon-arrow-down-empty:before {
  content: "\e905";
  color: #fff;
}
.icon-arrow-right-empty:before {
  content: "\e906";
  color: #fff;
}
.icon-arrow-left-empty:before {
  content: "\e906";
  color: #fff;
  -webkit-transform: rotate( 180deg );
  -ms-transform: rotate( 180deg );
  -o-transform: rotate(  180deg );
  transform: rotate( 180deg );
}
.icon-mail:before {
  content: "\e900";
  color: #fff;
}
.icon-cube-fb:before {
  content: "\e901";
  color: #fff;
}
.icon-arrow-down:before {
  content: "\e902";
  color: #fff;
}
.icon-circle-fb:before {
  content: "\e903";
  color: #fff;
}
.icon-circle-twitter:before {
  content: "\e904";
  color: #fff;
}

