.timeline {
	margin: 4rem 0;

	&-dates {
		position: relative;
		
		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 50%;
			top: 0;
			height: 100%;
			width: 2px;
			background-color: $dark-grey;
		}

	}

	&-date {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		width: 100%;
		margin: 2rem 0;

		&-inner {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: stretch;
		}

		&-image,
		&-content {
			flex-basis: 50%;
			width: 50%;
		}

		&-image {
			img {
				width: 100%;
				height: auto;
			}
		}

		.date {
			position: relative;
			font-size: 2em;
			font-weight: bold;
			background-color: $light-grey;
			padding: 1.5rem;
			text-align: right;
			font-family: $ff1;

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 100%;
				width: 40px;
				height: 40px;
				border-radius: 40px;
				background: $white;
				border: 5px solid $orange;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				-o-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				z-index: 30;
			}
		}

		.titre {
			font-size: 1.25em;
			padding: 0.75rem 1.5rem;
			text-align: right;
			margin: 0;
		}

		.donnees {
			padding: 0 1.5rem;
			text-align: right;
			color: #F09403;
			font-style: italic;
			font-size: .875em;
		}

		.texte {
			padding: 1.5rem;
			text-align: right;
			font-size: 1.0em;
			margin-bottom: 0em;
		}

		&.inverse {
			justify-content: flex-end;

			.date {
				text-align: left;

				&:after {
					left: auto;
					right: 100%;
					-webkit-transform: translate(50%, -50%);
					-ms-transform: translate(50%, -50%);
					-o-transform: translate(50%, -50%);
					transform: translate(50%, -50%);
				}
			}

			.titre,
			.texte,
			.donnees {
				text-align: left;
			}

		}

	}


}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP */
/* ----------------------------------------------------- */
@media (max-width: 1199px) {
	
}


/* ----------------------------------------------------- */
/* TABLETP */
/* ----------------------------------------------------- */
@media (max-width: 1023px) {
	
}

/* ----------------------------------------------------- */
/* TABLET */
/* ----------------------------------------------------- */
@media (max-width: 767px) {
	
	.timeline {
		
		&-dates {
			padding: 0 1rem;

			&:after {
				left: 1rem;
			}
		}

		&-date,
		&-date.inverse {
			&-inner {
				flex-wrap: wrap;
			}

			&-image,
			&-content {
				flex-basis: 100%;
				width: 100%;
			}

			&-image {
				order: 2;
				padding: 1.5rem;
			}

			.date{
				text-align: left;
				
				&:after {
					left: 0;
					right: auto;
					-webkit-transform: translate(-50%,-50%);
					-ms-transform: translate(-50%,-50%);
					-o-transform: translate(-50%,-50%);
					transform: translate(-50%,-50%);
				}
			}
		}
	}

}

/* ----------------------------------------------------- */
/* MOBILE */
/* ----------------------------------------------------- */
@media (max-width: 479px) {
	
	.timeline {


		&-date,
		&-date.inverse {
			position: relative;
			z-index: 32;
			background-color: $background-main;

			&-inner {
				flex-wrap: wrap;
			}

			&-image,
			&-content {
				flex-basis: 100%;
			}

			.date {
				text-align: center;
				padding: .5rem;

				// &:after {
				// 	display: none;
				// }
			}

			.titre {
				padding: 0;
				text-align: center;
			}

			.texte {
				padding: 0.5rem 0;
				text-align: center;
			}

		}


	}

}
