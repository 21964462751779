body {
    overflow-x: hidden;
}

.clear {
    clear: both;
}

a {
    color: $blue;
    transition: all .4s ease-out;

    &:hover {
        // color: darken($blue, 10%);
        color: $orange;
    }
}

body.single {
    .single-content {
        padding: 4vh 0;
    }
}

img.separate {
    width: auto;
    max-width: 48%;
    margin-right: 1%;

    &:last-child {
        margin-right: 0;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

/* ----------------------------------------------------- */
/* ANIMATIONS */
/* ----------------------------------------------------- */
@media (min-width: 768px) {
    .fadein {
        opacity: 0;
        position: relative;
        transition: all 300ms;

        &.fade-right {
            left: 30px;
            right: -30px; 
        }

        &.fade-left {
            right: 30px;
            left: -30px; 
        }

        &.fade-top {
            top: 15px; 
        }

        &.delayed {
            transition-delay: 200ms; 
        }

        @for $i from 1 through 10 {
            &.delayed-#{$i} {
                transition-delay: calc(#{$i} * 100ms);
            }
        }
    }
}

/* ----------------------------------------------------- */
/* WRAP EDITO */
/* ----------------------------------------------------- */
.wrap-edito {
    p {
        margin-top: 20px!important;
        margin-bottom: 15px!important;

        &:last-of-type {
            margin-bottom: 30px!important;
        }
    }
}

/* ----------------------------------------------------- */
/* WRAP CONTENT */
/* ----------------------------------------------------- */
.wrap-content {
    padding: 0;

    h1 {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 2.62em;
        color: $dark-blue-green;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 50px;
        padding-top: 50px;

        &:before {
            content: "";
            width: 30px;
            height: 30px;
            position: relative;
            display: block;
            background-color: $orange;
            margin-right: 15px;
        }
    }

    h2 {
        color: $dark-blue-green;
        font-family: $ff1;
        text-transform: uppercase;
        font-size: 1.4em;
    }

    h3 {
        color: $dark-blue-green;
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1.2em;
        text-transform: uppercase;
        display: inline;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .part-left,
    .part-right {
        margin: 50px 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .img-full {
        img {
            width: 100%;
            height: auto;
            max-height: 260px;
            display: block;
            margin: auto;
            object-fit: cover;
        }
    }

    .bold-orange {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1.5em;
        color: $orange;
    }

    .italic-orange {
        font-family: 'Montserrat-Italic', sans-serif;
        font-size: 1.12em;
        color: $orange;
        text-align: center;
        margin-bottom: 0;

        &:after {
            content: "";
            background-color: $light-dark-grey;
            width: 50px;
            height: 1px;
            position: relative;
            display: block;
            margin: 50px auto 10px auto;
        }
    }

    .card {

        border: none;
        margin-bottom: 30px;
        background-color: $light-grey;

        .card-header {
            border: none;
            padding-bottom: 0;

            .btn-link {
                color: $orange;
                text-decoration: none;
                text-transform: uppercase;
                font-family: 'Montserrat-Bold', sans-serif;
                font-size: 1.5em;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: left;

                &:hover {
                    text-decoration: none;
                    color: $blue;
                }

                &:before {
                    padding-top: 1px;
                    padding-left: 2px;
                    text-align: center;
                    content: "\e906";
                    font-size: 1em;
                    min-width: 47px;
                    min-height: 47px;
                    color: $white;
                    display: block;
                    font-family: 'Icomoon';
                    background-color: $orange;
                    border: 5px solid $grey;
                    border-radius: 100%;
                    -webkit-transform: rotateX(180deg);
                    transform: rotate(90deg);
                    transition: all 0.3s;
                    margin-right: 15px;
                }

                .adress {
                    font-family: 'Montserrat-Regular', sans-serif;
                    font-size: 0.7em;
                    color: $dark-blue-green;
                    text-transform: initial;
                }

                .post {
                    line-height: 1;
                    margin-top: 20px;
                }

                &.collapsed {
                    &:before {
                        text-align: center;
                        content: "\e906";
                        font-size: 1em;
                        min-width: 47px;
                        min-height: 47px;
                        color: $white;
                        display: block;
                        font-family: 'Icomoon';
                        background-color: $dark-blue-green;
                        border: 5px solid $grey;
                        border-radius: 100%;
                        transform: none;
                        transition: all 0.3s;
                        margin-right: 15px;
                    }
                }
            }
        }

        .card-body {
            padding: 0px 96px 60px 96px;
            background-color: rgba(0,0,0,.03);
            &:before {
                content: "";
                width: 150px;
                height: 1px;
                background-color: $middle-green;
                position: relative;
                display: block;
                margin-bottom: 20px;
            }

            p {
                font-family: 'Montserrat-Regular', sans-serif;
                font-size: 0.8em;
                color: $dark-blue-green;
                margin-bottom: 50px;
            }
        }
    }

    .link-dl {
        margin-bottom: 30px;

        a {
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 30px 15px;
            background-color: $grey;
            min-height: 150px;
            transition: all 0.3s;

            &:after {
                font-family: 'Icomoon';
                content: "\e908";
                color: $light-dark-grey;
                font-size: 1.8em;
                margin-left: 5px;
            }

            .content {
                .name-dl {
                    font-family: 'Montserrat-Bold', sans-serif;
                    font-size: 1em;
                    color: $orange;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                }

                .files-dl {
                    color: $dark-blue-green;
                    font-family: 'Montserrat-Regular', sans-serif;
                    font-size: 0.8em;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 5px;

                    &:before {
                        background-image: url('../images/pdf-logo.png');
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 30px;
                        display: block;
                        content: "";
                        margin-right: 7px;
                    }
                }
            }

            &:hover {
                background-color: $orange;
                transition: all 0.3s;

                &:after {
                    font-family: 'Icomoon';
                    content: "\e908";
                    color: $white;
                    font-size: 1.8em;
                }

                .content {
                    .name-dl {
                        font-family: 'Montserrat-Bold', sans-serif;
                        font-size: 1em;
                        color: $white;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                    }

                    .files-dl {
                        color: $white;
                        font-family: 'Montserrat-Regular', sans-serif;
                        font-size: 0.8em;
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 5px;

                        &:before {
                            background-image: url('../images/pdf-logo.png');
                            background-repeat: no-repeat;
                            width: 20px;
                            height: 30px;
                            display: block;
                            content: "";
                            margin-right: 7px;
                        }
                    }
                }
            }
        }
    }

    .slick-prev:before, 
    .slick-next:before {
        color: $dark-blue-green;
    }

    table {
        background-color: #DCE6F2;
        width: 100%;
        border: 1px solid $white;
        border-collapse: collapse;
        color: #2A7DBF;

        th {
            background-color: #B9CDE5;
            text-align: center;
            font-weight: bold;
            padding: 1rem;
            border: 1px solid $white;
        }


        tr {
            &:nth-child(odd) {
                background-color: #DCE6F2;
            }

            &:nth-child(even) {
                background-color: #F2F2F2;
            }
        }

        td {
            padding: 1rem;
            border: 1px solid $white;

            img {
                margin: 0 auto !important;
            }
        }

    }
}

/* ----------------------------------------------------- */
/* CONTENT STD */
/* ----------------------------------------------------- */
.content-std {
    h2 {
        color: #F09403;
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1.5em;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    h3 {
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 1.125em;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    blockquote,
    blockquote p {
        clear: both;
        font-family: 'Montserrat-Italic', sans-serif;
        font-size: 1.125rem;
        color: #F09403;
        text-align: center;
        margin-bottom: 0;
    }

    hr {
        width: 50px;
        margin: 15px auto;
    }

    img {
        max-width:100%;
    }

    img.alignleft {
        float: left;
        margin-right: 30px;
        margin-bottom: 30px;
        max-width: 25%;
        height: auto;
    }

    img.alignright {
        float: right;
        margin-left: 30px;
        margin-bottom: 30px;
        max-width: 25%;
        height: auto;
    }

    img.aligncenter {
        display: block;
        margin: 30px auto;
        height: auto;
    }
}

/* ----------------------------------------------------- */
/* PAGINATION */
/* ----------------------------------------------------- */
.pagination {
    display: block;
    position: relative;
    text-align: center;
    margin: 50px 0 10px;

    a {
        display: inline-block;
        color: #F09403;
        font-size: 1.25em;
        margin: 0 20px;
        &.prev {
            position: absolute;
            left: 0;
        }
        &.next {
            position: absolute;
            right: 0;
        }
    }

    span {
        font-size: 1.25em;
    }
}

.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    .column {
        flex-basis: 50%;

        li {
            padding-bottom: 1em;
        }
    }
}



/* ----------------------------------------------------- */
/* WRAP JOB DL */
/* ----------------------------------------------------- */
.wrap-job-dl {
    p {
        color: $white;
    }
}

/* ----------------------------------------------------- */
/* COOKIE DISCLAIMER */
/* ----------------------------------------------------- */
#cookie_disclaimer {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #F2F2F2;
    padding: 20px;
    color: #333;
    z-index: 1000;

    .title {
        font-weight: 700;
        font-size: 1.225em;
        padding-right: 100px;
    }

    p {
        padding-right: 100px;
    }

    #cookie_stop {
        float: right;
        background:#043447;
        color: white;
        cursor: pointer;
        display: inline-block;
        font-weight: 900;
        height: 100%;
        padding: 10px 20px;
        text-align: center;
        text-transform: uppercase;
        vertical-align: top;
        width: 70px;
        transition: all 500ms;

        &:hover{
            background: black;
        }
    }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* RESPONSIVE */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */

/* ----------------------------------------------------- */
/* DESKTOP : #{1199} px */
/* ----------------------------------------------------- */
@media (max-width: 1199px) {
    
}


/* ----------------------------------------------------- */
/* TABLETP : #{990px} px */
/* ----------------------------------------------------- */
@media (max-width: 990px) {
    
    section {
        &.txt_img {
            .img {
                img {
                    height: 100%;
                    object-position: center;
                    object-fit: cover;
                }
            }
        }
    }

    .wrap-content {
        table {
            th,
            td {
                padding: .2rem;
            }
        }
    }

}

/* ----------------------------------------------------- */
/* TABLET : #{768px} px */
/* ----------------------------------------------------- */
@media (max-width: 768px) {
    
}

/* ----------------------------------------------------- */
/* TABLE : #{640px} px */
/* ----------------------------------------------------- */
@media (max-width: 640px) {
    
}

/* ----------------------------------------------------- */
/* 600px */
/* ----------------------------------------------------- */
@media screen and (max-width: 600px) {

    .columns {
        flex-wrap: wrap;
    }

    .column {
        flex-basis: 100%;
    }

}

/* ----------------------------------------------------- */
/* 550px */
/* ----------------------------------------------------- */
@media screen and (max-width: 550px) {

    // .content-std {
    //     img {
    //         &.alignleft,
    //         &.alignright {
    //             display: block;
    //             float: none;
    //             width: 100%;
    //             max-width: 100%;
    //             margin-right: 0;
    //             margin-left: 0;
    //         }
    //     }
    // }

}

/* ----------------------------------------------------- */
/* MOBILEP : #{480px} px */
/* ----------------------------------------------------- */
@media (max-width: 640px) {
    .wrap-content {
        .card {
            .card-header {
                padding: 0;

                .btn-link {
                    white-space: unset;
                }
            }
        }
    }
}


@media screen and (max-width: 1199px) {

    .form-std, .page-template-template-contact form {
        padding: 30px;
    }
}

@media (max-width : 767.99px) {
    .wrap-content {
        h1 {
            font-size: 1.5em;
            margin-bottom: 30px;
        }

        .part-left,
        .part-right {
            margin: 15px 0;
        }

        .card {
            .card-header {
                .btn-link {
                    font-size: .9em;

                    &:before {
                        padding-top: 5px;
                    }

                    .post {
                        white-space: initial;
                    }
                }
            }

            .card-body {
                padding: 0px 15px 60px 15px;
            }
        }
    }

    .wrap-job-dl {
        h4 {
            font-size: 1.1em;
        }
    }
}

