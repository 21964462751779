$white : #ffffff;
$background-main: #f3f3f3;
$light-grey: #F2F2F2;
$light-dark-grey : #D0D6D6;
$grey : #F2F4F4;
$dark-grey: #333333;
$black : #000000;

$light-green: #EEFDFF;
$middle-green : #849FAF;
$orange : #F09403;
$blue: #04648D;
$dark-blue-green: #043447;

$ff1: 'Montserrat-Bold', sans-serif;
$trans: all .4s ease-out;

$screen-lg: 1199px;
$screen-md: 990px;
$screen-sm: 768px;
$screen-xs: 640px;
$screen-xxs: 480px;